import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root",
})
export class ValidationService {
  constructor(private utils: UtilityService) {}

  promotionValidations() {
    return {
      amountoff: {
        deal: this.allowNumberOnly,
        upto: this.allowNumberOnly,
        min: this.allowNumberOnly,
        max: this.allowNumberOnly,
        code: this.allowAlphaNumericOnly,
      },
      poff: {
        deal: this.allowPercentageOnly,
        on: this.allowAlphaNumericOnly,
        weight: this.allowNumberOnly,
        upto: this.allowNumberOnly,
        min: this.allowNumberOnly,
        max: this.allowNumberOnly,
        code: this.allowAlphaNumericOnly,
      },
      cashback: { deal: this.allowNumberOnly, on: this.allowNumberOnly },
      couponcash: { deal: this.allowNumberOnly, on: this.allowNumberOnly },
      bogo: { on: this.allowAlphaNumericOnly, deal: this.allowNumberOnly },
      bogopoff: {
        deal: this.allowPercentageOnly,
        on: this.allowAlphaNumericOnly,
      },
      freegift: { on: this.allowNumberOnly, was: this.allowAlphaNumericOnly },
    };
  }

  itemValidations() {
    return {
      lessprice: {
        deal: this.allowNumberOnly,
        was: this.allowNumberOnly,
        on: this.allowAlphaNumericOnly,
        weight: this.allowNumberOnly,
      },
    };
  }

  allowAlphaNumericOnly(event, allowedLength?) {
    allowedLength = allowedLength || 35;
    if (event.type === "paste") {
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData("text");
      if (pastedText.length > allowedLength) {
        return false;
      }
      const match = pastedText.match(/^[0-9a-zA-Z&'.,\s]+$/);
      if (!match) {
        return false;
      }
      return true;
    }
    if (
      event.currentTarget.value &&
      event.currentTarget.value.length > allowedLength - 1
    ) {
      return false;
    }
    const charCode = event.which ? event.which : event.keyCode;
    const regex = new RegExp("^[a-zA-Z0-9&'., ]+$");
    const str = String.fromCharCode(charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  allowPercentageOnly(event) {
    if (event.type === "paste") {
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData("text");
      if (pastedText.length > 2) {
        return false;
      }
      const match = pastedText.match(/^[0-9&]+$/);
      if (!match) {
        return false;
      }
      return true;
    }
    if (event.currentTarget.value && event.currentTarget.value.length > 1) {
      return false;
    }

    // Only Numbers 0-9
    const charCode = event.which ? event.which : event.keyCode;
    const regex = new RegExp("^[0-9]+$");
    const str = String.fromCharCode(charCode);
    if (regex.test(str)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  allowNumberOnly(event) {
    if (event.type === "paste") {
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData("text");
      if (pastedText.length > 10) {
        return false;
      }
      const match = pastedText.match(/^[0-9&]+$/);
      if (!match) {
        return false;
      }
      return true;
    }

    if (event.currentTarget.value && event.currentTarget.value.length > 9) {
      return false;
    }
    // Only Numbers 0-9
    const charCode = event.which ? event.which : event.keyCode;
    const regex = new RegExp("^[0-9]+$");
    const str = String.fromCharCode(charCode);
    if (regex.test(str)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

}
