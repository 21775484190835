import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Order } from "../../../models/order.model";

@Component({
  selector: "app-order-item",
  templateUrl: "./order-item.component.html"
})
export class OrderItemComponent implements OnInit {

  @Input()
  order: Order;

  @Input()
  seenBy: string;

  constructor(private popover: PopoverController) { }

  ngOnInit() {}
}
