import { Injectable } from "@angular/core";
import { Store } from "src/models/store.model";
import { Order } from "../models/order.model";

@Injectable({
    providedIn: "root",
})

export class StoreValidate {

    errors: Array<any>;
    storeInfo: Store;

    constructor() { }

    public isValid(storeInfo: Store) {
        return new Promise((resolve) => {
            this.errors = [];
            this.storeInfo = storeInfo;
            this.getProcessedStoreInfo();
            const areRequiredFeildsValid = this.isRequired(),
                areDineInFieldsValid = this.isDineInValidated(),
                arePickupFieldsValid = this.isPickupValidated(),
                areDeliveryFieldsValid = this.isDeliveryValidated()

            if (storeInfo &&
                areRequiredFeildsValid &&
                areDineInFieldsValid &&
                arePickupFieldsValid &&
                areDeliveryFieldsValid
            ) {
                resolve({ text: 'success' });
            } else {
                resolve({ text: 'error', errors: this.errors })
            }
        });
    }

    getProcessedStoreInfo() {
        let si = this.storeInfo;
        if (!si.isdinein) {
            si.avgdineintime = 0;
        }
        if (!si.isdelivery) {
            si.avgdeliverytime = 0;
        }
        if (!si.ispickup) {
            si.avgpickuptime = 0;
        }
        this.storeInfo = si;
    }

    isRequired() { // 
        const si = this.storeInfo;
        if (!si || !si.name || !si.type ||  !si.storephone || si.categories.length === 0) {
            this.errors.push({ text: 'Missing Required Fields' });
            return false;
        }
        if (!si.address || !si.address.latlong || !si.address.latlong.latitude) {
            this.errors.push({ text: 'Missing Address - Contact Store Admin' });
            return false;
        }
        return true;
    }

    isDineInValidated() {
        const si = this.storeInfo;
        if (si.isdinein && !si?.avgdineintime) {
            this.errors.push({ text: 'Missing Dinein Information' });
            return false;
        }
        return true;
    }

    isDeliveryValidated() {
        const si = this.storeInfo;
        if (si.isdelivery && !si?.avgdeliverytime) {
            this.errors.push({ text: 'Missing Delivery Information' });
            return false;
        }
        return true;
    }

    isPickupValidated() {
        const si = this.storeInfo;
        if (si.ispickup && !si?.avgpickuptime) {
            this.errors.push({ text: 'Missing Pickup Information' });
            return false;
        }
        return true;
    }

    isDistanceTooFar() {

    }

    isCustomerInStore() {

    }
}
