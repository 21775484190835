import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { APP_CONFIG, BaseAppConfig } from "./app.config";
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { FirebaseAuthentication } from "@ionic-native/firebase-authentication/ngx";
import { ComponentsModule } from "./components/components.module";
import { CommonModule } from "@angular/common";
import { WebIntent } from "@ionic-native/web-intent/ngx";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AllInOneSDK } from "@awesome-cordova-plugins/all-in-one-sdk/ngx";
import { SMS } from "@awesome-cordova-plugins/sms/ngx";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
// import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { Deeplinks } from "@awesome-cordova-plugins/deeplinks/ngx";

import { soundService } from "src/services/sound.service";
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { ImageCropperModule } from 'ngx-image-cropper';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    TranslateModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IonicStorageModule.forRoot(),
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    ImageCropperModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FirebaseX,
    BarcodeScanner,
    FirebaseAuthentication,
    { provide: APP_CONFIG, useValue: BaseAppConfig },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    WebIntent,
    AllInOneSDK,
    SMS,
    // AndroidPermissions,
    Deeplinks,
    NativeAudio,
    soundService,
    Geolocation,
    NativeGeocoder

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
