import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { UtilityService } from "../../../services/utility.service";
import { Router } from "@angular/router";
import { Address } from "src/models/address.model";
import { Geolocation } from '@ionic-native/geolocation/ngx';
declare var google;

@Component({
    selector: "app-add-map-address",
    templateUrl: "./add-map-address.component.html"
})
export class AddMapAddressComponent implements OnInit {

    @Output()
    onMapSelected = new EventEmitter();

    @Input()
    address: any;

    @Input()
    fromPage: any;

    marginTop = -500;

    uid: string;

    addressmodel = new Address();

    addressesList: Array<object>;
    showAddressForm = true;
    defaultAddressId: any;
    addressGeoResult: any;
    addressLatLong: { latitude: any; longitude: any; };
    searchaddressLatLong: { latitude: any; longitude: any; };

    constructor(
        private utils: UtilityService,
        private alertController: AlertController,
        private modalController: ModalController,
        private geolocation: Geolocation,
        private router: Router
    ) { }

    ngOnInit() {
        setTimeout(v => {
            this.marginTop = 0;
            if (this.fromPage === 'add-address') { // this does not matter

                // edit case
                if (this.address) {
                    this.addressmodel = this.address;
                }
                this.setSearchLatLong();
                this.addressesList = this.utils?.addresses || []; // check if we need this
                // if (this.addressesList.length > 0) {
                //     this.setDefaultAddress();
                // }
                // this.utils.isAddressChanged.subscribe(res => {
                //     if (res) {
                //         this.setDefaultAddress();
                //     }
                // });
            }
        }, 0);
    }

    dismiss() {
        this.marginTop = -500;
        setTimeout(v => {
            this.onMapSelected.emit(false);
        }, 400);
    }


    updateMapAddress(event) {
        if (event.streetAddress) {
            this.addressmodel.streetaddress = event.streetAddress;
        }
        if (event.addressLatLong) {
            this.addressLatLong = event.addressLatLong
        }
    }

    async searchAddress(event) {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': this.addressmodel.streetaddress }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                console.log('result', results);
                this.addressGeoResult = results;
            } else {
                // alert('Error - ' + results + ' & Status - ' + status)
            }
        });
    }

    selectedAddress(address) {
        if (address) {
            this.addressmodel.streetaddress = address.formatted_address;
            this.searchaddressLatLong = {
                latitude: address.geometry.location.lat(),
                longitude: address.geometry.location.lng()
            };
            this.addressGeoResult = [];
            this.addressLatLong = this.searchaddressLatLong; // addressLatLong is comes from event and selectedAddress
        }
    }


    addAddresstoLocal(addressList) {
        this.utils.setUpdateAddressesToLocalStorage(addressList);
        this.utils.showToast('Address Updated');
        this.resetAddressForm();
        if (this.addressesList.length === 1) {
            // set default
            this.modalController.dismiss(null);
        }
    }

    saveAddress() {
        if (this.fromPage === 'add-address') {
            this.addAddress();
        } else {
            this.onMapSelected.emit({streetaddress : this.addressmodel.streetaddress,addresslatlong : this.addressLatLong});
        }
    }

    addAddress() {
        if (this.addressmodel.id) {
            this.saveEditedAddress();
        } else {
            this.saveNewAddress();
        }

        this.addAddresstoLocal(this.addressesList);
        this.utils.isAddressChanged.next(true);
    }

    saveNewAddress() {
        const randomId = this.utils.getRandomNumber();
        let newAddress: any = {
            id: randomId,
            contactname: this.addressmodel.contactname,
            contactphone: this.addressmodel.contactphone,
            addresstype: this.addressmodel.addresstype,
            housenumber: this.addressmodel.housenumber,
            streetaddress: this.addressmodel.streetaddress,
            landmark: this.addressmodel.landmark,
            latlong: this.addressLatLong,
            isdefault: false
        };

        // If there is not addresses at all - we set the first one to true
        if (!this.addressesList || this.addressesList.length === 0) {
            newAddress.isdefault = true;
            this.defaultAddressId = newAddress.id;
        }
        this.addressesList.push(newAddress);
    }

    saveEditedAddress() {
        this.addressmodel.latlong = this.addressLatLong;
        const index = this.addressesList.findIndex((e: any) => e.id === this.addressmodel.id);
        this.addressesList[index] = this.addressmodel;
    }

    // setDefaultAddress() {
    //     const defaultAddress = this.utils.getDefaultAddress();
    //     this.defaultAddressId = defaultAddress.id;
    // }

    resetAddressForm() {
        this.onMapSelected.emit(false);
        this.showAddressForm = false;
    }

    async setSearchLatLong() {
        if (this.addressmodel?.latlong?.latitude) {
            this.searchaddressLatLong = this.addressmodel.latlong;
            return;
        }
        const currentLatLong: any = await this.geolocation.getCurrentPosition();
        if (currentLatLong?.coords?.latitude) {
            this.searchaddressLatLong = currentLatLong.coords;
            return;
        }

        this.searchaddressLatLong = this.utils.location?.latlong; // check this one
    }

}
