import { Injectable } from "@angular/core";
import {
    AngularFireStorage,
    AngularFireUploadTask,
} from "@angular/fire/storage";
import { Observable } from "rxjs";
import { UtilityService } from "./utility.service";
import { AuthService } from "./auth.service";
import { StoreService } from "./store.service";

@Injectable({
    providedIn: "root",
})
export class ImageService {
    maxSize: number;
    file: File;

    fileUploadTask: AngularFireUploadTask;
    UploadedImageURL: Observable<string>;
    imageRef: any;
    fileStoragePath: string;

    constructor(
        private afStorage: AngularFireStorage,
        private utils: UtilityService,
        private authService: AuthService,
        private storeService: StoreService
    ) { }

    resizeImage(file, maxSize) {
        const reader = new FileReader();
        const image = new Image();
        const canvas = document.createElement("canvas");
        const dataURItoBlob = (dataURI: string) => {
            const bytes =
                dataURI.split(",")[0].indexOf("base64") >= 0
                    ? atob(dataURI.split(",")[1])
                    : (<any>window).unescape(dataURI.split(",")[1]);
            const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
            const max = bytes.length;
            const ia = new Uint8Array(max);
            for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        const resize = () => {
            let width = image.width;
            let height = image.height;
            if (width < maxSize && height < maxSize) {
                this.utils.showToast('Image Size too small');
                return false;
            }

            // if (width > height) {
            //     if (width > maxSize) {
            //         height *= maxSize / width;
            //         width = maxSize;
            //     }
            // } else {
            //     if (height > maxSize) {
            //         width *= maxSize / height;
            //         height = maxSize;
            //     }
            // }

            // let resizingFactor = 0.7;
            let ratio = Math.min(maxSize/width, maxSize/height);

            canvas.width = width * ratio;
            canvas.height = height * ratio;
            canvas.getContext("2d").drawImage(image, 0, 0, width * ratio, height * ratio);
            let dataUrl = canvas.toDataURL("image/jpeg");
            return dataURItoBlob(dataUrl);
        };

        return new Promise((success, error) => {
            if (!file.type.match(/image.*/)) {
                error(new Error("Not an image"));
                return;
            }

            reader.onload = (readerEvent: any) => {
                image.onload = () => success(resize());
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    }


    // resizeImage(file, maxSize) {
    //     const reader = new FileReader();
    //     const image = new Image();
    //     const canvas = document.createElement("canvas");
    //     const dataURItoBlob = (dataURI: string) => {
    //         const bytes =
    //             dataURI.split(",")[0].indexOf("base64") >= 0
    //                 ? atob(dataURI.split(",")[1])
    //                 : (<any>window).unescape(dataURI.split(",")[1]);
    //         const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    //         const max = bytes.length;
    //         const ia = new Uint8Array(max);
    //         for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    //         return new Blob([ia], { type: mime });
    //     };
    //     const resize = () => {
    //         let width = image.width;
    //         let height = image.height;

    //         if (width < maxSize && height < maxSize) {
                
    //         }
    //         let ratio = Math.min(maxSize/width, maxSize/height);
    //         // if (width > height) {
    //         //     if (width > maxSize) {
    //         //         height *= maxSize / width;
    //         //         width = maxSize;
    //         //     }
    //         // } else {
    //         //     if (height > maxSize) {
    //         //         width *= maxSize / height;
    //         //         height = maxSize;
    //         //     }
    //         // }

    //         // let resizingFactor = 0.7;

    //         canvas.width = width * ratio
    //         canvas.height = height * ratio;
    //         canvas.getContext("2d").drawImage(image, 0, 0, width, height);
    //         let dataUrl = canvas.toDataURL("image/jpeg");
    //         return dataURItoBlob(dataUrl);
    //     };

    //     return new Promise((success, error) => {
    //         if (!file.type.match(/image.*/)) {
    //             error(new Error("Not an image"));
    //             return;
    //         }

    //         reader.onload = (readerEvent: any) => {
    //             image.onload = () => success(resize());
    //             image.src = readerEvent.target.result;
    //         };
    //         reader.readAsDataURL(file);
    //     });
    // }

    async uploadStoreImageToStorage(file, uid, path) {
        this.fileStoragePath = `filesStorage/${path}-${uid}`;
        this.imageRef = this.afStorage.ref(this.fileStoragePath);
        await this.afStorage.upload(this.fileStoragePath, file);
        if (!file || !uid) {
            return null;
        }
        this.UploadedImageURL = this.imageRef.getDownloadURL();
        return this.UploadedImageURL;
    }

    async uploadProductImageToStorage(file, sid, path) {
        this.fileStoragePath = `filesStorage/${path}-${sid}`;
        this.imageRef = this.afStorage.ref(this.fileStoragePath);
        await this.afStorage.upload(this.fileStoragePath, file);
        if (!file || !sid) {
            return null;
        }
        this.UploadedImageURL = this.imageRef.getDownloadURL();
        return this.UploadedImageURL;
    }

    // async uploadImageToStorage(file, uid, path) {
    //   this.utils.spinner = true;
    //   if (path === "i") {
    //     this.fileStoragePath = `items/${uid[0]}-${uid[1]}`; // Storage path
    //     this.imageRef = this.afStorage.ref(this.fileStoragePath); // Image reference
    //   } else if (path === "s" || path === "p") {
    //     this.fileStoragePath = `filesStorage/${path}-${uid}`; // Storage path
    //     this.imageRef = this.afStorage.ref(this.fileStoragePath); // Image reference
    //   }
    //   // File upload task
    //   await this.afStorage.upload(this.fileStoragePath, file);
    //   if (!file || !uid) {
    //     return null;
    //   }
    //   this.UploadedImageURL = this.imageRef.getDownloadURL();
    //   this.UploadedImageURL.subscribe((resp) => {
    //     if (path === "profile") {
    //       this.utils.updateProfilePicToLocalStorage(this.utils.uid, resp);
    //       this.authService.updateUserImage(this.utils.uid, resp).then((res) => {
    //         this.utils.showToast("Image Updated");
    //         this.utils.spinner = false;
    //       });
    //     } else if (path === "store") {
    //       this.authService.updateStoreImage(this.utils.uid, resp).then((res) => {
    //         this.utils.showToast("Image Updated");
    //         this.utils.spinner = false;
    //       });
    //     } else if (path === "item") {
    //       const storeId = uid[0];
    //       const itemId = uid[1];
    //       this.storeService.updateItemImage(storeId, itemId, resp).then((res) => {
    //         this.utils.showToast("Image Updated");
    //         this.utils.spinner = false;
    //       });
    //     }
    //   });
    // }
}
