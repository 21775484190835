import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "src/models/store.model";

@Component({
  selector: "app-store-promotion",
  templateUrl: "./store-promotion.component.html"
})

export class StorePromotionComponent implements OnInit {

  @Input()
  store: Store; // when type is defined this needs to change

  constructor(private router: Router) {}

  ngOnInit(): void {}

  // orderPromotion(item) {
  //   this.promotionEvent.emit({ item });
  // }

  goToStore() {
    this.router.navigate(['./store/' + this.store.id])
  }
}
