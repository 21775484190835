import { Component, OnInit } from "@angular/core";
import { Platform, LoadingController, NavController } from "@ionic/angular";
import { UtilityService } from "../services/utility.service";
import { Observable } from "rxjs/internal/Observable";
import { Deeplinks } from "@awesome-cordova-plugins/deeplinks/ngx";
import { StorePage } from "./store/store.page";
import { OrderService } from "src/services/order.service";
import { soundService } from "src/services/sound.service";
import { AuthService } from "src/services/auth.service";
import { Router } from "@angular/router";
import { Storage } from '@ionic/storage-angular';
import { Order } from "src/models/order.model";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  store: any;
  addStore = false;
  UploadedImageURL: Observable<string>;
  selectTab: any;
  scannedData: {};
  storeId: string;


  constructor(
    private platform: Platform,
    public utils: UtilityService,
    private deeplinks: Deeplinks,
    public loadingCtrl: LoadingController,
    private orderService: OrderService,
    // private soundService: soundService,
    private authService: AuthService,
    private router: Router,
    private navCtrl: NavController,
    private storage: Storage,
    private geolocation: Geolocation,
    private splashScreen: SplashScreen
  ) {
    this.initializeApp();
    this.utils.projectSettings = this.utils.getProjectSettings();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("cordova")) {
        this.deepLink();
        this.utils.platform = "android";
        localStorage.setItem("platform", "android");
        this.getpushNotifications();
      } else if (this.platform.is("ios")) {
        this.utils.platform = "ios";
        localStorage.setItem("platform", "ios");
      }
      // below on is trigger sound everytime its loaded
      // this.soundService.preload('notificationSound', 'assets/audio/notification-sound.mp3');
    });
  }

  async ngOnInit() {
    this.storage.create(); // are we checking of already checking before creating it or creating new everytime
    this.checkLocation();
    this.initializeApp();
    this.utils.initialize(); // this will get location, uid, userinfo from local storage

    // if (this.utils.uid && !this.utils.userlatlong) {
    //  await this.getPositionLatLong();
    // }
    // introduce settimeout later
    this.getAllOrders();
  }

  async getPositionLatLong() {
      await this.geolocation.getCurrentPosition({enableHighAccuracy: false,
        timeout: 5000}).then((resp) => {
        const latlong = {
          latitude: resp.coords.latitude,
          longitude: resp.coords.longitude
        }
       // this.authService.updateUserLatLongToLocalStorage(this.utils.uid, latlong);
       this.utils.userlatlong = latlong;
      }).catch((error) => {
        console.log('Error getting location', error);
      });
  }

  getAllOrders() {
    if (this.utils.uid) {
      this.loadUserOrders(this.utils.uid);
    }
    const si = this.utils.userOwnStore;
    if (si?.id && this.utils.uid === si.id) {
      this.navCtrl.navigateRoot(['./store-orders']);
      return;
    }
    const se = this.utils.userEmployeeStore;
    if (se?.id && se.approvedemployees.includes(this.utils.uid)) {
      this.navCtrl.navigateRoot(['./store-orders']);
      return;
    }
  }

  checkLocation() {
    const location = localStorage.getItem("location");
    if (!location) {
      this.router.navigate(["landing"]);
    }
  }

  loadUserOrders(uid) {
    const lastCheckedDate = this.utils.getUserOrdersLastCheckedDate();
    this.orderService.getUserOrders(uid, lastCheckedDate).subscribe((res) => {
      const rLen = res?.length || 0;
      if (rLen > 0 && res[rLen - 1].updatedon.toDate() > lastCheckedDate) {
        this.utils.processUserOrders(res);
      }
    });
  }

  getpushNotifications() {
    FCM.getInitialPushPayload().then( data => {
      if(data) {
        if(data.wasTapped) {
             // we know the user launched the app by clicking on the notification
             console.log(JSON.parse(data.data));
        }
      }
    })
  }

  // loadStoreOwnerOrders(uid) {
  //   const lastCheckedDate = this.utils.getStoreOrdersLastCheckedDate();
  //   this.orderService.getStoreOrders(uid, lastCheckedDate).subscribe((res: Array<Order>) => {
  //     const rLen = res?.length || 0;
  //     if (rLen > 0 && res[rLen - 1].updatedon.toDate() > lastCheckedDate) {
  //       this.utils.processStoreOrders(res);
  //     }
  //   });
  // }

  // getEmployeeMadeOrders(storeId, uid) {
  //   this.orderService
  //     .getEmployeeStoreOrders(storeId, uid)
  //     .subscribe((res) => {
  //       if (res && res.length > 0) {
  //         // this.utils.setStoreOrdersLastCheckedDate();
  //         // this.utils.updateAsctoDescStoreOrder(res, uid);
  //         // this.utils.setOrderNotificationDot(); replace
  //       }
  //     });
  // }

  deepLink() {
    this.deeplinks
      .route({
        "/store/:storeid": StorePage,
      })
      .subscribe((match) => {
        console.log("path", match);
      });
  }
}
