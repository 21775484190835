// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAnYOCJwIRlzx7-8W3mMmeD7-leNbkYNBQ",
    authDomain: "my-town-360.firebaseapp.com",
    projectId: "my-town-360",
    storageBucket: "my-town-360.appspot.com",
    messagingSenderId: "682269248335",
    appId: "1:682269248335:web:9e0ad38a1dc9580824933a",
    measurementId: "G-9S0M3XVSNZ"
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibmV4dGdlbmFwcHMxNDYiLCJhIjoiY2ttOThxb241MDhpNDJ2cWwyNmY5emdmMiJ9.H_zdfR-03qKtQhignrDSng',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
