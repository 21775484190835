import { Component, Input, OnInit, ViewChild, SimpleChanges } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Billing, Order } from "src/models/order.model";
import { Store } from "src/models/store.model";
import { UtilityService } from "src/services/utility.service";
import { PopOverComponent } from "../popover/popover";

@Component({
    selector: "app-billing",
    templateUrl: "./billing.component.html"
})
export class BillingComponent implements OnInit {

    @Input()
    cartInfo: any;

    @Input()
    initializebilling: any;

    billing: Billing;
    store: Store;
    showCharges = false;

    isCartStorePromoAvailable = false;
    neededForDiscount = 0;
    estimatedDiscount = 0;
    estimatedFinalBill = 0;

    constructor(
        private popover: PopoverController,
        private utils: UtilityService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.store = this.cartInfo.store;
        if (this.store && this.store.id) {
            this.isCartStorePromoAvailable = this.isAnyStorePromo();
            this.cartInfo = this.utils.getCalculatedOrder(this.cartInfo);
            this.billing = this.cartInfo.billing;
            this.calculateEstimatedDiscounts();
        } else {
            this.billing = this.cartInfo.billing;
        }
    }

    isAnyStorePromo() {
        if (this.store?.promotion && this.store.promotion.deal) {
            return true;
        }
        return false;
    }

    calculateEstimatedDiscounts() {
        if (this.isCartStorePromoAvailable && !this.billing.storepromotiondiscount) {
            const spromo = this.store.promotion;
            this.neededForDiscount = spromo.minbill - this.billing.subtotal;
            if (spromo.promotype === 'poff') {
                this.estimatedDiscount = (spromo.minbill/100) * spromo.deal;
                this.estimatedDiscount = spromo.upto && this.estimatedDiscount > spromo.upto ? spromo.upto : this.estimatedDiscount;
            } else {
                this.estimatedDiscount = spromo.deal;
            }
            if (this.estimatedDiscount) {
                this.estimatedFinalBill = this.billing.grandtotal - this.estimatedDiscount;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initialize()
    }


}
