import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { UtilityService } from 'src/services/utility.service';

@Component({
    selector: 'app-my-header',
    templateUrl: './my-header.component.html'
})
export class MyHeaderComponent {

    @Input()
    showCart = false;

    @Input()
    showStoreOrders = false;

    @Input()
    showLocation = false;

    @Input()
    showBackButton = false;

    @Input()
    showCloseButton = false;

    @Input()
    isAutoClose = false;

    @Input()
    showScanner = false;

    @Input()
    title: string = '';

    @Input()
    subtitle: string = '';

    @Input()
    rightLink: string = '';

    @Output()
    onBackEvent = new EventEmitter();

    @Output()
    onCloseEvent = new EventEmitter();

    @Output()
    onEditEvent = new EventEmitter();

    @Output()
    onTitleClick = new EventEmitter();

    @Output()
    onRightLinkClick = new EventEmitter();

    scannedData: {};
    barcodeScannerOptions: BarcodeScannerOptions;

    constructor(
        private utils: UtilityService,
        public router: Router,
        private barcodeScanner: BarcodeScanner,
        private alertController: AlertController,
        private modalController: ModalController,
        private navCtrl: NavController) {
        this.barcodeScannerOptions = {
            showTorchButton: true,
            showFlipCameraButton: true,
        };
    }

    changeLocation() {
        this.router.navigate(['/location']);
    }

    scanBar() {
        this.barcodeScanner
            .scan()
            .then((barcodeData) => {
                var url = barcodeData.text;
                var pathname = new URL(url).pathname;
                console.log(pathname);
                this.router.navigate(["." + pathname]);
                this.scannedData = barcodeData;
            })
            .catch((err) => {
                console.log("Error", err);
            });
    }

    openCartPage() {
        this.router.navigate(['./store/' + this.utils.cart.storeid]);
    }

    goBack(event) {
        this.navCtrl.back();
        event.stopPropagation();
    }

    handleTitleClick(event) {
        this.onTitleClick.emit(event);
    }

    handleCloseClick(event) {
        if (this.isAutoClose) {
            this.modalController.dismiss(event);
        } else {
            this.onCloseEvent.emit(event);
        }
    }

    handleEditClick(event) {
        this.onEditEvent.emit(event);
    }

    handleRightLinkClick(event) {
        this.onRightLinkClick.emit(event);
    }

}
