import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { SearchUserPage } from "src/app/search-user/search-user.page";
import { Order } from "src/models/order.model";
import { cashfreeService } from "src/services/cashfree.service";
import { UtilityService } from "src/services/utility.service";

@Component({
    selector: "app-order-timeline",
    templateUrl: "./order-timeline.component.html"
})
export class OrderTimelineComponent implements OnInit {

    @Input()
    order: Order;

    @Output()
    onTimelineStatusClick = new EventEmitter<any>();

    isAssignedPerson = false;
    isPaymentOpened = false;
    isCustomer: boolean;

    constructor(private utils: UtilityService,
        private alertController: AlertController,
        private modalController: ModalController,
        private cashfreeService: cashfreeService) { }

    ngOnInit() {
        if (this.order && this.order.id) {
            this.isAssignedPerson = this.order.assigneeid === this.utils.uid;
            this.isCustomer = this.utils.isUserCustomer(this.order.storeid);
        }
    }

    confirmOrder(preparationTime) {
        const date = this.utils.getNewDate();
        const status = { time: date, timetofinish: preparationTime };
        this.onTimelineStatusClick.emit({ status, type: 'received' });
    }

    orderReadyForPickup() {
        const date = this.utils.getNewDate();
        const status = { time: date };
        this.onTimelineStatusClick.emit({ status, type: 'ready' });
    }

    orderReadyForDelivery(assigneeInfo) {
        const date = this.utils.getNewDate();
        const status = { time: date };
        this.onTimelineStatusClick.emit({ status, type: 'ready', assigneeInfo });
    }

    orderPickedUpForDelivery(estimatedDeliveryTime) {
        const date = this.utils.getNewDate();
        const status = { time: date, timetofinish: estimatedDeliveryTime };
        this.onTimelineStatusClick.emit({ status, type: 'pickedup' });
    }

    orderCompleted() {
        const date = this.utils.getNewDate();
        const status = { time: date };
        this.onTimelineStatusClick.emit({ status, type: 'completed' });
    }

    //   orderPaymentStatus(event) {
    //     this.isPaymentOpened = false;
    //     if (event.status) {
    //       this.onTimelineStatusClick.emit(event);
    //     }
    //   }

    async paidByCashAlert() {
        const message = "Did You Collected the Cash";
        const alert = await this.alertController.create({
            header: "Are you sure ?",
            message,
            buttons: [
                { text: "No" },
                { text: "Yes", handler: () => { this.paidByCash(); } },
            ],
        });
        await alert.present();
    }

    paidByCash() {
        const date = this.utils.getNewDate();
        const status = { time: date };
        this.onTimelineStatusClick.emit({ status, user: null, type: 'cashpaid' });
    }

    onlinePayRequested(user) {
        const date = this.utils.getNewDate();
        const status = { time: date };
        this.onTimelineStatusClick.emit({ status, user, type: 'onlinepaymentrequested' });
    }

    sendBillToCustomer(user) {
        const date = this.utils.getNewDate();
        const status = { time: date };
        this.onTimelineStatusClick.emit({ status, user, type: 'billsent' });
    }

    updateBillPayByUser(event) {
        const date = this.utils.getNewDate();
        const status = { time: date, paymentinfo: event.paymentInfo };
        this.onTimelineStatusClick.emit({ status, user: null, type: 'upipaid' });
    }

    //   updatePayment(event) {
    //     this.isPaymentOpened = true;
    //   }

    async setEstitamedTime(title) {
        const inputs: any = this.utils.getTimeOptions();
        const alert = await this.alertController.create({
            header: title,
            inputs,
            buttons: [
                { text: "Cancel" },
                {
                    text: "Ok",
                    handler: (data: any) => this.handleEstitamedTime(data),
                },
            ],
        });
        await alert.present();
    }

    handleEstitamedTime(data) {
        if (this.order.currentstatus === 'ready') {
            this.orderPickedUpForDelivery(data);
        } else if (this.order.currentstatus === 'new') {
            this.confirmOrder(data);
        }
    }

    async setCancelReason(event) {
        const alert = await this.alertController.create({
            header: 'Order Cancel Reason',
            inputs: [
                { type: "radio", label: "Out of Stock", value: "Out of Stock" },
                { type: "radio", label: "Store Closed", value: "Store Closed" },
                { type: "radio", label: "Delivery Not Available", value: "Delivery Not Available" },
            ],
            buttons: [
                { text: "Cancel" },
                {
                    text: "Ok",
                    handler: (data: any) => {
                        if (event === 'refund' || this.order?.paymentstatus?.txStatus === 'SUCCESS') {
                            this.initializeRefund(data)
                            return;
                        }
                        if (event === 'cancel') {
                            const res = null;
                            this.orderCancel(res, data);
                        }
                    }
                },
            ],
        });
        await alert.present();
    }

    initializeRefund(data) {
        this.cashfreeService
            .intializeRefund(this.order)
            .subscribe((res: any) => {
                if (res.refund_status) {
                    this.orderCancel(res, data)
                    this.utils.showToast(res.message);
                } else {
                    this.utils.showToast(res.message);
                }
            }
            )
    }

    orderCancel(res, data) {
        // refund_status ["SUCCESS", "PENDING", "CANCELLED", "ONHOLD"]
        const date = this.utils.getNewDate();
        const status: any = { time: date, cancelreason: data };
        if (res) {
            status.refundId = res.refundId;
            status.refundstatus = res.refund_status
        }
        this.onTimelineStatusClick.emit({ status, type: 'cancel' });
    }

    checkRefundStatus() {
        const refundid = 'refund' + this.order.id
        this.cashfreeService
            .GetRefundStatus(this.order.id, refundid)
            .subscribe((res) => {
                console.log(res);
            })
    }

    async openSearchUserPage(fromPage) {
        const modal = await this.modalController.create({
            component: SearchUserPage,
            componentProps: { fromPage },
        });
        modal.onDidDismiss().then((res) => {
            if (res && res.data) {
                this.handleAfterUserSearched(res, fromPage);
            }
        });
        return await modal.present();
    }

    handleAfterUserSearched(res, fromPage) {
        if (fromPage === 'assigndelivery') {
            this.orderReadyForDelivery(res.data)
            return;
        };
        if (fromPage === 'cart') {
            this.onlinePayRequested(res.data)
            return;
        };
        if (fromPage === 'billsent') {
            this.sendBillToCustomer(res.data)
            return;
        };
    }

}
