import { Pipe, PipeTransform } from '@angular/core';
import { parseISO } from 'date-fns';

@Pipe({
    name: 'timeAgo'
})

export class TimeAgo implements PipeTransform {
    public transform(date: any, args?: any): string {
        let result = '';
        const seconds = date?.seconds * 1000|| date.getTime();
        const prev: any = new Date(seconds),
        present: any = new Date(),
        diff: any = (present - prev)/1000,
        ago = args && args === 'ago' ? ' ago'  : '';

        if (diff < 45) {
            return Math.round(diff) + 's' + ago;
        }
        if (diff > 45 && diff < 2700) {
            return Math.round(diff/60) + 'm' + ago;
        }
        if (diff > 2700 && diff < 79200) {
            return Math.round(diff/3600) + 'h' + ago;
        }
        if (diff > 79200 && diff < 259200) {
            return Math.round(diff/(60*60*24)) + 'd' + ago;
        }

        return prev.toLocaleDateString();
    }
}

@Pipe({
    name: 'Time'
})

export class Time implements PipeTransform {
    public transform(date: any): string {
        const prev: any = parseISO(date);

        return prev.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }
}

@Pipe({
    name: 'TimeString'
})

export class TimeString implements PipeTransform {
    public transform(date: any): string {
        let result = '';
        const prev: any = date.toDate(),
        present: any = new Date()

        return prev.toLocaleDateString();
    }
}

@Pipe({
    name: 'Ms'
})

export class Ms implements PipeTransform {
    public transform(ms: any): string {
        const days = Math.floor(ms / (24*60*60*1000)) + "Mins";
        const daysms = ms % (24*60*60*1000);
        const hours = Math.floor(daysms / (60*60*1000));
        const hoursms = ms % (60*60*1000);
        const minutes = Math.floor(hoursms / (60*1000));
        const minutesms = ms % (60*1000);
        const sec = Math.floor(minutesms / 1000);

        return days + ":" + hours + ":" + minutes + ":" + sec;
    }
}