import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';
import { StoreService } from 'src/services/store.service';
import { UtilityService } from 'src/services/utility.service';
import { ValidationService } from 'src/services/validation.service';

@Component({
    selector: 'app-search-user',
    templateUrl: './search-user.page.html'
})
export class SearchUserPage implements OnInit {
    showSearch = false;
    searchText: number;
    userList: Array<any>;
    fromPage: any;

    searchPerformed = false;

    constructor(
        public validationService: ValidationService,
        private utils: UtilityService,
        private authService: AuthService,
        private alertController: AlertController,
        private modalController: ModalController,
        private navParams: NavParams,
        private storeService: StoreService
    ) { }

    ngOnInit() {
        this.fromPage = this.navParams.get("fromPage");
        if (this.fromPage === 'assigndelivery') {
            this.getEmployees();
        }
    }

    getEmployees() {
        const storeEmployee = this.storeService
            .getStoreEmployees(this.utils.uid)
            .subscribe((res) => {
                this.userList = res;
                // Adding employee to the list as well
                this.userList.push({ fullname: this.utils.fullName, phonenumber: this.utils.phoneNumber, pic: this.utils.profilePic, id: this.utils.uid });
                storeEmployee.unsubscribe();
            });
    }

    // getStoreOwnerDetails() {
    //     this.userDetails.push({ fullname: this.utils.fullName, phoneNumber: this.utils.phoneNumber});
    //     // const storeEmployee = this.authService.getUserByPhoneNumber(this.utils.phoneNumber).subscribe(
    //     //     (res) => {
    //     //         this.userDetails.push(res[0]);
    //     //         storeEmployee.unsubscribe();
    //     //     });
    // }

    searchUser() {
        var phoneNumber = this.searchText.toString();
        this.authService
            .getUserByPhoneNumber(phoneNumber)
            .subscribe((res: any) => {
                this.searchPerformed = true;
                if (res.length > 0) {
                    this.userList = res;
                }
            });
    }

    async selectUser(item) {
        const alert = await this.alertController.create({
            header: "Confirm User",
            message: "Are you sure you want to add " + item?.fullname,
            buttons: [
                { text: "Cancel" },
                {
                    text: "Ok",
                    handler: () => this.returnUserDetails(item),
                },
            ],
        });
        await alert.present();
    }

    returnUserDetails(item) {
        this.modalController.dismiss(item);
    }

    dismiss() {
        this.modalController.dismiss(null);
    }

    inviteUser() {
        const userDetails = {
            phonenumber: this.searchText,
            status: 'inviteuser'
        }
        this.modalController.dismiss(userDetails);
    }

}
