import { Component, Input, OnInit } from "@angular/core";
import { OrderService } from "src/services/order.service";
import { UtilityService } from "../../../services/utility.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-employee-cart",
  templateUrl: "./employee-cart.component.html"
})
export class EmployeeCartComponent implements OnInit {

  @Input()
  cartInfo: any;

  @Input()
  showButtons = false;

  guestPhoneNumber: string;

  @Input()
  isCustomer: boolean;

  constructor(
    private orderService: OrderService,
    private router: Router,
    private utils: UtilityService) { }

  ngOnInit(): void {}

//   placeDineInOrder() {
//     const newDate = this.utils.getNewDate();
//     this.cartInfo.ordereddate = newDate;
//     this.cartInfo.status = {};

//     this.cartInfo.currentstatus = 'new';
//     this.cartInfo.deliveryoption = 'dinein';
//     this.cartInfo.status['new'] = { time: newDate };
//     this.cartInfo.updatedon = newDate;
//     this.cartInfo.updatedbyid = this.utils.uid;
//     this.cartInfo.userid = '';
//     this.cartInfo.username = '';
//     this.cartInfo.phonenumber = '';
//     this.cartInfo.assigneeid = this.utils.uid;
//     this.cartInfo.assigneename = this.utils.fullName;
//     this.cartInfo.assigneephonenumber = this.utils.phoneNumber;
//     this.cartInfo.storecreated = true;
//     delete this.utils.cart.store;

//     this.createUpdateStoreOrder(this.cartInfo.orderid);
//   }

//   createUpdateStoreOrder(orderId) {
//     this.orderService
//       .createStoreOrder(this.cartInfo, orderId)
//       .then((res) => {
//         this.utils.showToast("Order Created for " + this.cartInfo.tablenumber);
//         this.utils.resetCart();
//         // where does he go from here.. because cart will be empty.. send to store..
//         this.router.navigate(['./store-orders']);
//       })
//       .catch((err) => {
//         console.log(err);
//         this.utils.spinner = false;
//         this.utils.handleServiceErrors(err);
//       });
//   }

}
