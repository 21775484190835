import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, NavController, ToastController } from "@ionic/angular";
import { soundService } from "./sound.service";
import { Storage } from '@ionic/storage';
import { formatISO, format, parseISO } from 'date-fns';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { async } from "@angular/core/testing";
import { Billing, Order } from "../models/order.model";
import { BehaviorSubject } from "rxjs";
import { Address } from "../models/address.model";
import { Location } from "../models/location.model";


@Injectable({
    providedIn: "root",
})
export class UtilityService {
    locationModel = new Location();
    // quantitiesAndProductsMap = new BehaviorSubject<any>({});
    finalprice = 0;
    spinner = false;
    uid = "";
    phoneNumber = "";
    fullName = "";
    // userStoreId = "";
    location: any = null; // needs to be a model
    projectSettings: any = null;
    userOwnStore: any = null;
    userEmployeeStore: any = null;

    userOrders: Array<any> = [];
    notSeenUserOrders: Array<any> = [];

    storeOrders: Array<any> = [];
    notSeenStoreOrders: Array<Order> = [];
    filtersApplied = false;

    order: any = null;
    viewingOrderId: string;
    viewingOrder: BehaviorSubject<Order>;
    isAddressChanged: BehaviorSubject<boolean>;

    cart: any = null;
    homeStores: any = null;
    
    messages: any;
    profilePic: string;
    recentVisited: any = null;
    platform: string;
    lastPage: string;
    priority = 10;
    address: any;
    email: string;
    response: string;
    isOTPRequested = false;
    otpphoneNumber = "";
    remainingSecond = 60;
    addresses: any;
    // defaultaddress: any;
    transactionOrders: any;
    userlatlong: any;
    // isUserStoreEmployee: string;

    constructor(
        public toast: ToastController,
        private router: Router,
        private alertController: AlertController,
        private soundService: soundService,
        private storage: Storage,
        private geolocation: Geolocation,
        private navCtrl: NavController
    ) {
        this.viewingOrder = new BehaviorSubject<Order>(null);
        this.isAddressChanged = new BehaviorSubject<boolean>(false);
    }

    /*****************************************************************************
    ------------------------------------------------------------------------------
    // INITIALIZE ---- Main place where the entire project starts ----------------
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    async initialize() {
        this.location = JSON.parse(localStorage.getItem("location"));
        this.platform = localStorage.getItem("platform");
        this.addresses = JSON.parse(localStorage.getItem("addresses")) || [];
        this.cart = JSON.parse(localStorage.getItem("cart"));
        const uid = localStorage.getItem("uid");
        if (uid) {
            this.uid = uid;
            this.fullName = localStorage.getItem(uid + "fullname");
            this.email = localStorage.getItem(uid + "email");
            this.phoneNumber = localStorage.getItem(uid + "phonenumber");
            this.profilePic = localStorage.getItem(uid + "profilepic");
            this.userEmployeeStore = JSON.parse(localStorage.getItem(uid + 'useremployeestore'));
            this.userlatlong = JSON.parse(localStorage.getItem(uid + "userlatlong"));

            const userStoreInfo = localStorage.getItem(uid + "userownstore");
            this.userOwnStore = userStoreInfo && userStoreInfo !== "undefined" ?
                JSON.parse(localStorage.getItem(uid + "userownstore")) : null;

            const storageUserOrders = await this.storage.get(uid + 'userOrders');
            const userOrders = JSON.parse(storageUserOrders);
            this.userOrders = userOrders && userOrders.length > 0 ? userOrders : [];

            // we can only call if the user has a store.....
            const storageStoreOrders = await this.storage.get(uid + 'storeOrders');
            const storeOrders = JSON.parse(storageStoreOrders);
            this.storeOrders = storeOrders && storeOrders.length > 0 ? storeOrders : [];

            this.recentVisited = this.getRecentlyVisitedStores();
            this.filterStoreTransactions(this.storeOrders);

        }
        this.messages = this.getToastMessages();
    }

    reset() {
        this.finalprice = 0;
        this.spinner = false;
        this.uid = "";
        this.phoneNumber = "";
        this.fullName = "";

        this.location = null;
        this.userOwnStore = null;
        this.userOrders = [];
        this.storeOrders = [];
        // this.newStoreOrderDot = false;
        // this.isNewOrderIndicator = false;

        this.viewingOrder.next(null);
        this.viewingOrderId = null;
        this.order = null;
        this.messages = null;
        this.profilePic = "";
        this.recentVisited = null;
        this.platform = "";
        this.lastPage = "";
    }

    /*****************************************************************************/

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Below Methods Updating Information in Model before updating in the database
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/
    public updateLocationDetailsInModel(result) {
        if (this.isMissingLocationInfo()) {
            this.throwError("mi", "Missing Location Information");
            return false;
        }
        // result.locationname = this.location.name;
        // result.city = this.location.city;
        // result.zipcode = this.location.zipcode;
        // result.zone = this.location.zone;
        return result;
    }

    public getKeyWordsForStore(name) {
        name = name.toLocaleLowerCase();
        let keywords = [name];
        const splitBySpaces = name.split(" ");
        keywords.concat(splitBySpaces);
        const length = name.length;
        for (let i = 4; i < length; i++) {
            const newString = name.substring(0, i);
            if (!keywords.includes(newString)) {
                keywords.push(newString);
            }
        }
        return keywords;
    }

    public updateCreatedDetailsInModel(result) {
        if (this.isMissingUserInfo()) {
            this.throwError("mi", "Missing User Information");
            return false;
        }
        result.createdbyname = this.fullName;
        result.createdbyphoneno = this.phoneNumber;
        result.createdbyid = this.uid;
        result.createdon = this.getNewDate(); // this needs to change to actual date

        // adding the details for updated same as created only upon creation
        result = this.updateUpdatedDetailsInModel(result);
        return result;
    }

    public updateUpdatedDetailsInModel(result) {
        if (this.isMissingUserInfo()) {
            this.throwError("mi", "Missing User Information");
            return false;
        }
        result.updatedbyname = this.fullName;
        result.updatedbyphoneno = this.phoneNumber;
        result.updatedbyid = this.uid;
        result.updatedon = this.getNewDate(); // this needs to change to actual date
        return result;
    }

    /*---------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Checking if any of the fields are missing before entering into database
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/
    isMissingStoreInfo(storeInfo) {
        if (
            !storeInfo ||
            !storeInfo.id ||
            !storeInfo.city ||
            !storeInfo.zipcode ||
            !storeInfo.address ||
            !storeInfo.name ||
            !storeInfo.locationname
        ) {
            return true;
        }
        return false;
    }

    isMissingUserInfo() {
        if (!this.fullName || !this.uid || !this.phoneNumber) {
            return true;
        }
        return false;
    }

    isMissingLocationInfo() {
        if (
            !this.location.locality ||
            !this.location.zipcode ||
            !this.location.latlong
            // !this.location ||
            // !this.location.name ||
            // !this.location.city ||
            // !this.location.zipcode ||
            // !this.location.zone
        ) {
            return true;
        }
        return false;
    }

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Below Methods Updating Information in Local Storage after getting for db
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    public setUserInfoInLocalStorage(userInfo, uid) {
        this.updateFullNameToLocalStorage(uid, userInfo["fullname"]);
        this.updateEmailToLocalStorage(uid, userInfo["email"]);
        this.updateProfilePicToLocalStorage(uid, userInfo["pic"]);
        this.setUserOrdersLastCheckedDate(null);
    }

    updateFullNameToLocalStorage(uid, fullName) {
        localStorage.setItem(uid + "fullname", fullName);
        this.fullName = fullName;
    }

    updateEmailToLocalStorage(uid, email) {
        localStorage.setItem(uid + "email", email);
        this.email = email;
    }

    updateProfilePicToLocalStorage(uid, imageUrl) {
        localStorage.setItem(uid + "profilepic", imageUrl);
        this.profilePic = imageUrl;
    }

    updateStoreIdToJustLocalStorage(uid, storeId) {
        localStorage.setItem(uid + "storeid", storeId);
    }

    setUpdateAddressesToLocalStorage(addresses) {
        this.addresses = addresses;
        localStorage.setItem("addresses", JSON.stringify(addresses));
    }

    public getSelectedAddress() {
        if (this.addresses?.length > 0) {
            if (this.addresses.length === 1) {
                return this.addresses[0];
            }
            const selectedAddr: any = this.addresses.filter(v => v.selected);
            if (selectedAddr[0] && selectedAddr[0].selected) {
                return selectedAddr[0];
            }
            const defaultAddr: any = this.addresses.filter(v => v.default);
            if (defaultAddr[0] && defaultAddr[0].selected) {
                return defaultAddr[0];
            }
        }
        return '';
    }

    public setDefaultAddress(defaultAddressId) {
        if (this.addresses?.length > 0) {
            this.addresses.forEach(v => {
                v.default = false;
                if (v.id === defaultAddressId) {
                    v.default = true;
                }
            });
        }
        this.setUpdateAddressesToLocalStorage(this.addresses);
        this.isAddressChanged.next(true);
    }

    public setSelectedAddress(selectedAddressId) {
        if (this.addresses?.length > 0) {
            this.addresses.forEach(v => {
                v.selected = false;
                if (v.id === selectedAddressId) {
                    v.selected = true;
                }
            });
        }
        this.setUpdateAddressesToLocalStorage(this.addresses);
        this.isAddressChanged.next(true);
        this.navCtrl.back();
    }

    public removeSelectedAddress() {
        if (this.addresses?.length > 0) {
            this.addresses.forEach(v => {
                v.selected = false;
            });
        }
        this.setUpdateAddressesToLocalStorage(this.addresses);
        this.isAddressChanged.next(true);
    }

    public deleteAddress(deleteAddressId) {
        if (this.addresses?.length > 0) {
            const addressesList = this.addresses.filter((i: any) => i.id !== deleteAddressId);
            this.addresses = addressesList;
        }
        this.setUpdateAddressesToLocalStorage(this.addresses);
        this.isAddressChanged.next(true);
    }

    public addNewAddress(newAddress) {
        newAddress.id = this.getRandomNumber();
        if (!this.addresses || this.addresses.length === 0) {
            this.addresses = [];
            newAddress.default = true;
            newAddress.selected = true;
        }
        this.addresses.push(newAddress);
        this.setUpdateAddressesToLocalStorage(this.addresses);
        this.isAddressChanged.next(true);
        if (this.addresses.length === 1 && newAddress.addresstype !== 'add-store') {
            this.navCtrl.back();
        }
    }

    public editAddress(address, editId?) {
        const index = this.addresses.findIndex((e: any) => e.id === editId || address.id);
        this.addresses[index] = address;
        this.setUpdateAddressesToLocalStorage(this.addresses);
        this.isAddressChanged.next(true);
        if (this.addresses.length === 1) {
            this.navCtrl.back();
        }
    }

    public updateUserStoreInfoInLocalStorage(uid, storeInfo) {
        this.userOwnStore = storeInfo;
        localStorage.setItem(uid + "userownstore", JSON.stringify(storeInfo));
    }

    public updateEmployeeStoreInfoInLocalStorage(uid, storeInfo) {
        this.userEmployeeStore = storeInfo;
        localStorage.setItem(uid + "useremployeestore", JSON.stringify(storeInfo));
    }

    public removeEmployeeFromStore(uid) {
        localStorage.removeItem(uid + "useremployeestore");
    }

    public updatePendingStoreEmployeesInLocalStorage(uid, employees) {
        localStorage.setItem(uid + "pendingemployees", JSON.stringify(employees));
    }

    public getPendingStoreEmployeesInLocalStorage(uid) {
        return JSON.parse(localStorage.getItem(uid + "pendingemployees")) || [];
    }

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Recently visited stores 
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    updateRecentVisits(result) {
        this.recentVisited = this.getRecentlyVisitedStores();
        const index = this.recentVisited.map(e => e.id).indexOf(result.id);
        if (index > -1) {
            this.recentVisited.splice(index, 1);
        }
        this.recentVisited.unshift(result);
        localStorage.setItem(this.uid + "recentVisits", JSON.stringify(this.recentVisited));
    }

    public getRecentlyVisitedStores() {
        const recentVisits = JSON.parse(
            localStorage.getItem(this.uid + "recentVisits")
        );
        return recentVisits && recentVisits.length > 0 ? recentVisits : [];
    }

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Code for Store Orders - 
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    processStoreOrders(res) {
        this.notSeenStoreOrders = res;
        if (this.router.url.includes("/store-orders") && !this.filtersApplied) {
            // check for no filters applied condition
            this.setStoreOrdersInStorage();
            return;
        }
        this.setNewStoreOrderToast();
    }

    setNewStoreOrderToast() {
        const newOrders = this.notSeenStoreOrders.filter(order => order.currentstatus === 'new');
        const len = newOrders?.length;
        if (len > 0) {
            const ordersText = len > 1 ? 'orders' : 'order';
            this.showToast('You have ' + len + ' new ' + ordersText);
        }
    }

    setStoreOrdersInStorage() {
        if (this.notSeenStoreOrders && this.notSeenStoreOrders.length > 0) {
            if (this.storeOrders.length === 0) {
                this.storeOrders = this.getDescSortedOrders(this.notSeenStoreOrders);
            } else if (this.notSeenStoreOrders?.length > 0) {
                this.notSeenStoreOrders.forEach(order => {
                    this.deleteAndAddStoreOrders(order);
                });
            }
            this.saveStoreOrderToStorage(this.storeOrders);
        }
    }

    deleteAndAddStoreOrders(order) {
        if (this.viewingOrderId && this.viewingOrderId === order.id) {
            this.viewingOrder.next(order);
            order.status[order.currentstatus].seen = true;
        }
        const index = this.storeOrders.findIndex(object => {
            return object.id === order.id;
        });
        if (index > -1) {
            this.storeOrders.splice(index, 1);
        }
        if (this.userOwnStore && order.updatedbyid === this.userOwnStore.id) {
            order.status[order.currentstatus].seen = true;
        }
        if (this.userEmployeeStore && order.updatedbyid === this.userEmployeeStore.id) {
            order.status[order.currentstatus].seen = true;
        }
        this.storeOrders.unshift(order);
    }

    public saveStoreOrderToStorage(orders) {
        this.setStoreOrdersLastCheckedDate(orders[0]);
        this.storage.set(this.uid + 'storeOrders', JSON.stringify(orders));
        this.notSeenStoreOrders = [];
    }

    public setStoreOrdersLastCheckedDate(latestStoreOrder) {
        let newDate = this.getNewDate().getTime();
        if (latestStoreOrder && latestStoreOrder.updatedon) {
            newDate = latestStoreOrder.updatedon.toDate().getTime(); // just thinking if saving in seconds will make the query easy in firebase
        }
        localStorage.setItem(this.uid + "storeOrdersLastSeen", JSON.stringify(newDate));
    }

    public getStoreOrdersLastCheckedDate(): any {
        const dateString = JSON.parse(localStorage.getItem(this.uid + "storeOrdersLastSeen"));
        return new Date(dateString);
    }

    public updateSeenForStoreOrdersInStorage(order) {
        if (order.status && !order.status[order.currentstatus]?.seen) {
            this.storeOrders.forEach((item: any) => {
                if (order.id === item.id) {
                    item.status[item.currentstatus].seen = true;
                }
            });
        }
        this.storage.set(this.uid + 'storeOrders', JSON.stringify(this.storeOrders));
    }

    filterStoreOrders(orders, filterBy) {
        const statuses = ['guest', 'new', 'received', 'ready', 'pickedup', 'completed'];
        return orders.filter((obj) => {
            return obj && obj.status[filterBy];
        });
    }

    filterStoreTransactions(res) {
        const transactionOrders = res.filter((obj) => {
            return obj && obj.paymenttype === 'upi' && obj.paymentstatus.txStatus === 'SUCCESS';
        });
        this.transactionOrders = transactionOrders;
    }

    public getDescSortedOrders(res) {
        let descSortedList = [];
        for (var order of res) {
            descSortedList.unshift(order);
        }
        return descSortedList;
    }

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Code for User Orders - 
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    processUserOrders(res) {
        this.notSeenUserOrders = res;
        if (this.router.url.includes("/orders")) {
            // check for no filters applied condition
            // this.newUserOrderDot = false;
            this.setUserOrdersInStorage();
            return;
        }
        // this.newUserOrderDot = true;
    }

    setUserOrdersInStorage() {
        if (this.notSeenUserOrders?.length > 0) {
            if (this.userOrders.length === 0) {
                this.userOrders = this.getDescSortedOrders(this.notSeenUserOrders);
            } else if (this.notSeenUserOrders?.length > 0) {
                this.notSeenUserOrders.forEach(order => {
                    this.deleteAndAddUserOrders(order);
                });
            }
            this.saveUserOrderToStorage(this.userOrders);
        }
    }

    deleteAndAddUserOrders(order) {
        if (this.viewingOrderId && this.viewingOrderId === order.id) {
            this.viewingOrder.next(order);
            order.status[order.currentstatus].seen = true;
        }
        const index = this.userOrders.findIndex(object => {
            return object.id === order.id;
        });
        if (index > -1) {
            this.userOrders.splice(index, 1);
        }
        if (order.updatedbyid === this.uid) {
            order.status[order.currentstatus].seen = true;
        }
        this.userOrders.unshift(order);
    }

    public saveUserOrderToStorage(orders) {
        this.setUserOrdersLastCheckedDate(orders[0]);
        this.storage.set(this.uid + 'userOrders', JSON.stringify(orders));
        this.notSeenUserOrders = [];
    }

    public setUserOrdersLastCheckedDate(latestUserOrder) {
        let newDate = this.getNewDate().getTime();
        if (latestUserOrder && latestUserOrder.updatedon) {
            newDate = latestUserOrder.updatedon.toDate().getTime();
        }

        localStorage.setItem(this.uid + "userOrdersLastSeen", JSON.stringify(newDate));
    }

    public setUserOrdersLastCheckedDateforNewUser() {
        let newDate = this.getNewDate().getTime() - (5 * 24 * 60 * 60 * 1000); // this will date 5days ago
        localStorage.setItem(this.uid + "userOrdersLastSeen", JSON.stringify(newDate));
    }

    public getUserOrdersLastCheckedDate(): any {
        const dateString = JSON.parse(localStorage.getItem(this.uid + "userOrdersLastSeen"));
        return new Date(dateString);
    }

    public updateSeenForUserOrdersInStorage(order) {
        if (order.status && !order.status[order.currentstatus]?.seen) {
            this.userOrders.forEach((item: any) => {
                if (order.id === item.id) {
                    item.status[item.currentstatus].seen = true;
                }
            });
        }
        this.storage.set(this.uid + 'userOrders', JSON.stringify(this.userOrders));
    }

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Sqlite Native Storage
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/
    public getOrdersNativeStorage(key) {
        return this.storage.get(key);
    }

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // LOGOUT
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/
    async logout(uid) {
        const alert = await this.alertController.create({
            header: "Log out?",
            message: "Are you sure you want to log out of the application?",
            cssClass: "store-page-alert",
            buttons: [
                {
                    text: "Cancel",
                    // cssClass: "secondary",
                },
                {
                    text: "Log out",
                    // cssClass: "secondary",
                    handler: () => {
                        this.confirmLogout(uid);
                    },
                },
            ],
        });

        await alert.present();
    }

    public confirmLogout(uid) {
        localStorage.removeItem("uid");
        localStorage.removeItem(uid + "phonenumber");
        localStorage.removeItem(uid + "fullname");
        localStorage.removeItem(uid + "storeid");
        localStorage.removeItem(uid + "profilePic");
        localStorage.removeItem("cart");
        localStorage.removeItem("orderitemslength");
        localStorage.clear();
        this.uid = null;
        this.userOrders = [];
        this.storeOrders = [];
        this.reset();
        this.storage.clear();
        this.showToast("Successfully logged out");
        this.router.navigate(["./"]);
        this.reloadPage();
    }

    /*------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Helper Functions which are reused all over the application should go here
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/
    public getToastMessages() {
        return {
            mi: "Missing Information! Please log out and log back in again",
            ps: "Promotion Created successfully",
            pu: "Promotion Updated successfully",
            itemCreated: "Item Created successfully.",
            itemUpdated: "Item Updated successfully.",
            orderCreated: "Order successfully created.",
            orderUpdated: "Order Updated",
            orderCancelled: "Order cancelled by store.",
            orderUpdatedByStore: "Order updated by store",
            orderCreatedByStore: "Order created by store",
            orderCompleted: "Order completed",
            orderNotFound: "Order cannot be found",
            orderCannotBeCancelled:
                "Order cannot be cancelled. Please call the store for any changes.",
        };
    }

    public showToast(msg) {
        const messgeObject: any = {
            message: msg,
            duration: 3000,
            position: "top",
        };
        this.toast.create(messgeObject).then((toastData) => {
            toastData.present();
        });
    }

    public throwError(name, desc) {
        this.spinner = false;
        const error = new Error(desc);
        error["name"] = name;
        error["desc"] = desc;
        throw error;
    }

    /*------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // Project Settings - Should be moved to its own file
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    public getProjectSettings() {
        const settings = {
            indiaPhonenNumberPreFix: "+91",
        };

        return settings;
    }

    /*------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // These methods are miscellaneous
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/

    public getConvertedLocation(googleComponents) {
        const components = googleComponents.address_components;
        const addressLatLong = {
            latitude: googleComponents.geometry.location.lat(),
            longitude: googleComponents.geometry.location.lng()
        }
 
        this.locationModel.latlong = addressLatLong;
        this.locationModel.formatted_address = googleComponents.formatted_address;
        Object.keys(this.locationModel).forEach(key => {
            const value = components.filter(addr => addr.types.includes(key));
            if (value.length > 0) {
                this.locationModel[key] = value[0]?.long_name;
            }
        });

        const finalizedAddress = {
            sublocality: this.locationModel.sublocality_level_1,
            sublocality2: this.locationModel.sublocality_level_2,
            locality: this.locationModel.locality,
            district: this.locationModel.administrative_area_level_2,
            state: this.locationModel.administrative_area_level_1,
            country: this.locationModel.country ,
            zipcode: this.locationModel.postal_code,
            latlong: this.locationModel.latlong,
            streetaddress: this.locationModel.formatted_address,
        }

        return finalizedAddress;
    }

    getCurrentPosition(success, error?) {
        const instance = this;
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 1000
        };

        if (error) {
            error = function (err) {
                instance.showToast('unavailable get current location. check  please your device location is ON/ Try Manually');
                console.log(err);
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }
        }

        navigator.geolocation.getCurrentPosition(success, error, options);
    }

    public async getPositionLatLong() {
        return this.geolocation.getCurrentPosition().then(async (resp) => {
            return {
                latitude: resp.coords.latitude,
                longitude: resp.coords.longitude
            };
        }).catch((error) => {
            console.log('Error getting location', error);
            return null;
        });
    }

    async getFromStorageAsync() {
        return await this.getPositionLatLong();
    }

    public getRandomNumber() {
        return Math.floor(10000 + Math.random() * 90000).toString();
    }

    public getRandomOrderNumber() {
        return Math.floor(100000000000 + Math.random() * 900000000000).toString();
    }

    public getNewDate() {
        return new Date();
        // return new Date().toISOString().split('-').join('').split(':').join('').split('.').join('').split('T').join('').split('Z').join('');
    }

    /*---------------------------------------------------------------------------
    ------------------------------------------------------------------------------
    // These methods are miscellaneous only for orders and order details
    ------------------------------------------------------------------------------
    ----------------------------------------------------------------------------*/
    public getCalculatedOrder(details) {
        // this.orderItemKeys = Object.keys(details.items); // trigger billing on delivery option change
        let billing = new Billing(details.billing);
        let subTotal = 0, totalItemsDiscounts = 0;
        for (var key in details.items) {
            if (details.items.hasOwnProperty(key)) {
                subTotal = subTotal + parseInt(details.items[key].quantity) * parseInt(details.items[key].deal);
                if (details.items[key].was) {
                    totalItemsDiscounts = totalItemsDiscounts + parseInt(details.items[key].quantity) * parseInt(details.items[key].was);
                }
            }
        }
        if (details.store?.packagingcost) {
            billing.packaging = details.store.packagingcost
        }
        if (details.deliveryoption !== 'delivery') {
            billing.additionalcharge = 0;
            billing.deliverycharge = 0;
        }
        billing.subtotal = subTotal;
        billing.gst = billing.subtotal * 0.05; // Standard but should be a global setting;
        billing.itemdiscount = totalItemsDiscounts;
        billing.storepromotiondiscount = this.getCalculatedDiscount(billing, details.store);
        billing.deliverycharge = this.calculateDeliveryCharge(details);
        billing.grandtotal = billing.subtotal + + billing.tip + billing.service + billing.packaging + billing.gst - billing.storepromotiondiscount;
        details.billing = billing;
        details.itemscount = this.getOrderItemsLength(details.items);
        localStorage.setItem("cart", JSON.stringify(details));
        return details;
    }

    getCalculatedDiscount(billing, store) {
        let discount = 0;
        const spromo = store.promotion;
        if (spromo?.deal) {
            if (spromo?.promotype === 'poff' && billing.subtotal > spromo?.minbill) {
                discount = (billing.subtotal / 100) * spromo?.deal;
                discount = spromo.upto && discount > spromo.upto ? spromo.upto : discount
                return discount;
            }
            if (spromo?.promotype === 'flat' && billing.subtotal > spromo?.minbill) {
                return spromo?.deal;
            }
        }
        return 0;
    }

    calculateDeliveryCharge(order) {
        if (order.deliveryoption === 'delivery') {
            if (!order.useraddress.latlong) {
                order.errors.userlatlong = { message: 'Cannot get user address' };
                return 0;
            }
            if (!order.store.position.geopoint) {
                order.errors.storelatlong = { message: 'Cannot get store address' };
                return 0;
            }

            delete order.errors?.userlatlong;
            delete order.store?.storelatlong;

            let deliveryFees = 0;
            const distance: any = this.getDistanceBetweenPoints(
                order.useraddress.latlong, order.store.position.geopoint, 'km').toFixed(2);

            deliveryFees = order.store.deliverybaseprice;
            if (parseInt(distance) < order.store.additionalchargeafter) {
                return deliveryFees;
            }

            const addtlDistance: any = distance - order.store.additionalchargeafter;
            deliveryFees = deliveryFees + parseInt(addtlDistance) * order.store.additionalchargeperkm;
            return deliveryFees;
        } else {
            return 0;
        }
    }

    public getOrderItemsLength(orderItems) {
        let length = 0;
        if (orderItems) {
            const itemKeys = Object.keys(orderItems);
            itemKeys.forEach((element) => {
                length = length + orderItems[element].quantity;
            });
        }
        return length;
    }

    public isUserCustomer(storeId) {
        if ((storeId === this.uid) ||
            (this.userEmployeeStore && this.userEmployeeStore.id === storeId)
        ) {
            return false;
        }
        return true;
    }

    async isUserInsideStore(storeInfo) {
        const position = await this.geolocation.getCurrentPosition();
        const currentPosition = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }

        const distance = this.getDistanceBetweenPoints(
            currentPosition,
            storeInfo.position.geopoint, 'km').toFixed(2);

        const storeradius = 0.10;
        if (parseInt(distance) < storeradius) {
            return true
        } else {
            return false
        }
    }

    async getLocatoinQueryLatLong() {
        let queryLatLong: any = this.location?.latlong || null,
        userLatLong: any;
        const instance = this;
        let success = function (pos) {
            if (pos?.coords?.latitude) {
                userLatLong = pos.coords;
            }
            if (userLatLong && queryLatLong) {
                const distance = instance.getDistanceBetweenPoints(userLatLong, queryLatLong, 'km').toFixed(2);
                if (parseInt(distance) < 10) {
                    queryLatLong = userLatLong;
                }
            }
            return queryLatLong;
        };
        await this.getCurrentPosition(success);
    }

    getDistanceBetweenPoints(start, end, units) {
        let earthRadius = {
            miles: 3958.8,
            km: 6371
        };
        let R = earthRadius[units || 'km'];
        let lat1 = start.latitude;
        let lon1 = start.longitude;
        let lat2 = end.latitude;
        let lon2 = end.longitude;
        let dLat = this.toRad((lat2 - lat1));
        let dLon = this.toRad((lon2 - lon1));
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;
        return d;
    }

    getTimeOptions() {
        return [
            { type: "radio", label: "5 Minutes", value: 300000 },
            { type: "radio", label: "10 Minutes", value: 600000 },
            { type: "radio", label: "15 Minutes", value: 900000 },
            { type: "radio", label: "30 Minutes", value: 1800000 },
            { type: "radio", label: "1 Hour", value: 3600000 },
            { type: "radio", label: "One Day", value: 86400000 },
            { type: "radio", label: "Two Days", value: 172800000 },
            { type: "radio", label: "One Week", value: 604800000 },
            { type: "radio", label: "One Month", value: 2592000000 }
        ]
    }

    toRad(x) {
        return x * Math.PI / 180;
    }


    resetOrder() {
        this.order = null;
    }

    resetCart() {
        this.cart = null;
        localStorage.removeItem("cart");
    }

    reloadPage() {
        window.location.href = window.location.href;
    }

    /*---------------------------------------------------------------------------
    /*---------------------------------------------------------------------------
    // Handle Errors - 
    ----------------------------------------------------------------------------*/
    handleServiceErrors(err) {
        if (err.name === "mi") {
            this.showToast(this.messages.mi);
            this.spinner = false;
        }
    }

    // globalize() {
    //   // Add this import
    //   import { TranslateService } from '@ngx-translate/core';

    //   const languagePriority = window.localStorage.getItem(
    //     Constants.KEY_DEFAULT_LANGUAGE
    //   );
    //   this.translate.setDefaultLang('en');
    //   const defaultLangCode = this.config.availableLanguages[0].code;
    //   this.translate.use(
    //       languagePriority && languagePriority.length
    //           ? languagePriority
    //           : defaultLangCode
    //   );
    //   this.setDirectionAccordingly(
    //       languagePriority && languagePriority.length
    //           ? languagePriority
    //           : defaultLangCode
    //   );
    // }

    // setDirectionAccordingly(lang: string) {
    //     switch (lang) {
    //         case 'ar': {
    //             this.rtlSide = 'rtl';
    //             break;
    //         }
    //         default: {
    //             this.rtlSide = 'ltr';
    //             break;
    //         }
    //     }
    // }
}