import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UtilityService } from "src/services/utility.service";

@Component({
    selector: "app-filters",
    templateUrl: "./filters.component.html"
})

export class FiltersComponent implements OnInit {

    @Input()
    selectedStatusFilter: string;

    @Input()
    selectedPaymentModeFilter: string;

    statusFilters: object = {
        filterby: 'Order Status',
        fields: [
            { status: 'new', label: 'New Orders' },
            { status: 'received', label: 'Started' },
            { status: 'ready', label: 'Completed' },
            { status: 'delivered', label: 'Delivered' }
        ]
    };

    paymentModeFilters: object = {
        filterby: 'Payment Mode',
        fields: [
            { status: 'cash', label: 'Cash' },
            { status: 'upi', label: 'Online' }
        ]
    };

    marginRight = -250;

    @Output()
    onFilterSelect = new EventEmitter();

    constructor(
        private utils: UtilityService
    ) { }

    ngOnInit() {
        setTimeout(v => {
            this.marginRight = 0;
        }, 0);
    }

    applyFilters() {
        this.onFilterSelect.emit({
            status: this.selectedStatusFilter,
            paymentMode: this.selectedPaymentModeFilter
        });
    }

    clearFilters() {
        this.onFilterSelect.emit({
            clear: true,
            status: '',
            paymentMode: ''
        });
    }

    dismiss() {
        this.onFilterSelect.emit(false);
    }

}
