import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { Locality, LocationService } from "src/services/location.service";
import { UtilityService } from "src/services/utility.service";
import { ValidationService } from "src/services/validation.service";
import * as geofirex from 'geofirex';
import { Observable } from "rxjs/internal/Observable";
import * as firebase from 'firebase/app';

@Component({
  selector: "app-add-locality",
  templateUrl: "./add-locality.page.html",
  styleUrls: ["./add-locality.page.scss"],
})
export class AddLocalityPage implements OnInit {
  geo = geofirex.init(firebase);
  points: Observable<any>;
  localityModel = new Locality();
  cities = [];
  city = "";
  pageTitle = "Add locality";
  lat: number;
  long: number;
  location: any;

  constructor(
    private locationService: LocationService,
    private utils: UtilityService,
    private router: Router,
    private modalController: ModalController,
    public validationService: ValidationService
  ) {}

  ngOnInit() {
    if (this.location) {
      this.localityModel = this.location;
      this.lat = this.location.position.geopoint.latitude;
      this.long = this.location.position.geopoint.longitude;
    }
  }

  async createCity() {
    this.localityModel.position = await this.geo.point(this.lat, this.long);
    if(this.localityModel.id) {
      this.locationService.updateCity(this.localityModel).then(async (result) => {
        this.utils.showToast("Locality Updated");
        this.modalController.dismiss(null);
      })
      .catch((err) => console.log(err));
      return ;
    }
    this.locationService
      .createCity(this.localityModel)
      .then(async (result) => {
        this.utils.showToast("Locality Created");
        this.modalController.dismiss(null);
      })
      .catch((err) => console.log(err));
  }

  dismiss() {
    this.modalController.dismiss(null);
  }
}
