import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { AuthService } from "src/services/auth.service";
import { StoreService } from "src/services/store.service";
import { UtilityService } from "src/services/utility.service";

@Component({
    selector: "app-store-footer",
    templateUrl: "./store-footer.component.html"
})
export class StoreFooterComponent implements OnInit {

    @Input()
    store: any;

    @Output()
    onLinkClick = new EventEmitter<any>();

    isStoreOwner: boolean = false;
    isEmployeeAvailableForDelivery: boolean;
    isEmployee: boolean;

    constructor(
        public utils: UtilityService,
        private router: Router,
        private storeService: StoreService,
        private authService: AuthService,
        private alertController: AlertController) { }

    ngOnInit() {
        if (this.utils?.uid === this.store.id) {
            this.isStoreOwner = true;
            this.utils.updateUserStoreInfoInLocalStorage(this.utils.uid, this.store);
        }
        if (this.store?.approvedemployees?.includes(this.utils.uid)) {
            this.isEmployee = true;
            this.utils.updateEmployeeStoreInfoInLocalStorage(this.utils.uid, this.store);
        }
        // if (this.isStoreOwner && !this.utils.userOwnStore || this.isEmployee && this.store.open === 'mopen') {
        //   this.enableStoreOrders();
        // }
        // need to add enable storeorders to employee 
        this.isEmployeeAvailableForDelivery
            = JSON.parse(localStorage.getItem(this.utils.uid + this.store.id + 'availablefordelivery')) || false;
    }

    goToStoreDashboard() {
        this.router.navigate(['./store-dashboard/' + this.store.id]);
    }

    handleClickedLinks(event) {
        this.onLinkClick.emit(event);
    }

    // only first time
    // enableStoreOrders() {
    //   if (this.isStoreOwner) {
    //     this.utils.updateUserStoreInfoInLocalStorage(this.utils.uid, this.store);
    //   }
    //   this.utils.setStoreOrdersLastCheckedDate(null); // from the time this trigger order will come
    //   // this.router.navigate(['./home']);
    //   // this.utils.reloadPage();
    // }

    // updateStoreId(event) {
    //   this.store.id = event;
    //   this.utils.updateStoreIdToLocalStorage(this.utils.uid, event);
    //   this.utils.reloadPage();
    // }

    claimStore() {
        if (this.store.status === "claimed" &&
            this.store.storephone === this.utils.phoneNumber) {
            const oldId = this.store.id;
            const params = this.getStoreParams();
            this.storeService
                .claimCreateStore(this.utils.uid, params).then((res: any) => {
                    if (res && res.text === 'success') {
                        this.handleAfterClaimStoreSuccess(oldId);
                        return true;
                    }
                    this.utils.showToast('Clear Errors');
                })
                .catch((err) => this.utils.handleServiceErrors(err));
        } else {
            this.showClaimAlert();
        }
    }

    getStoreParams() {
        this.utils.spinner = true;
        this.store = this.utils.updateLocationDetailsInModel(this.store);
        this.store.keywords = this.utils.getKeyWordsForStore(this.store.name); // splitting by spaces
        this.store.status = "transferred";
        this.store.id = this.utils.uid;
        return this.store;
    }

    handleAfterClaimStoreSuccess(oldId) {
        this.utils.showToast("Store claimed successfully");
        this.utils.updateUserStoreInfoInLocalStorage(this.utils.uid, this.store);
        this.utils.setStoreOrdersLastCheckedDate(null);
        this.deleteStore(oldId);
        this.utils.recentVisited = null;
        localStorage.removeItem(this.utils.uid + "recentVisits");
        //test
        this.router.navigate(['/store/' + this.utils.uid]);
        this.utils.reloadPage();
    }

    deleteStore(id) {
        this.storeService.deleteStore(id).then((res) => { });
    }

    async showClaimAlert() {
        const alert = await this.alertController.create({
            header: "Are you owner of the store ?",
            cssClass: "store-page-alert",
            message:
                "<br>Contact : " +
                this.store.storephone +
                "<br> Store id : " +
                this.store.id,
        });

        await alert.present();
    }

    // I think we are doing too much with the delivery for the store owner
    // changeDeliveryAvailability(ev) {
    //   this.storeService.updateEmployeetoSubCollection(this.store.id, this.utils.uid, { availablefordelivery: ev.detail.checked })
    //     .then((res) => {
    //       localStorage.setItem(this.utils.uid + this.store.id + 'availablefordelivery', JSON.stringify(ev.detail.checked));
    //       this.utils.showToast('Joined as Employee Successfully');
    //       this.isEmployeeAvailableForDelivery = ev.detail.checked;
    //     });
    // }

    async showAcceptOfferAlert() {
        const message = 'br>' + this.store.name + ' has offered you a job' +
            'Do you want to accept the offer to be an employee of ' + this.store.name + ' ?';

        const alert = await this.alertController.create({
            header: "Are you sure ?",
            cssClass: "",
            message,
            buttons: [
                {
                    text: "Yes",
                    // role: "redeem",
                    // cssClass: "secondary",
                    handler: () => {
                        this.acceptOfferAsEmployee();
                    },
                },
                {
                    text: "No",
                    // role: "cancel",
                    // cssClass: "secondary",
                },
            ],
        });

        await alert.present();
    }

    //Employee
    acceptOfferAsEmployee() {
        // below both calls can be combined to a single update -- Rishi do this one
        let pending = this.store.pendingemployees || [];
        pending = pending.filter(item => item !== this.utils.uid);

        let approved = this.store?.approvedemployees || [];
        approved.push(this.utils.uid);
        this.storeService.updateStore(this.store.id, { pendingemployees: pending, approvedemployees: approved })
            .then((res) => {
                this.addEmployeetoSubCollection();
                this.utils.showToast('Join as Employee  Successfully');
            })
    }

    addEmployeetoSubCollection() {
        const userDetails = {
            fullname: this.utils.fullName,
            uid: this.utils.uid,
            phonenumber: this.utils.phoneNumber,
            status: 'active'
        };
        this.storeService.addEmployeetoSubCollection(this.store.id, userDetails.uid, userDetails)
            .then((res) => {
                // this is used for pulling store guest orders
                this.utils.updateEmployeeStoreInfoInLocalStorage(this.utils.uid, this.store);
                this.updatePendingEmployeesInStorage(userDetails);
                this.utils.showToast('Joined as Employee Successfully');
                this.onLinkClick.emit('reload');
            });
    }

    updatePendingEmployeesInStorage(res) {
        let pending = this.utils.getPendingStoreEmployeesInLocalStorage(this.store.id);
        pending = pending.filter(employee => employee.id !== res.id);
        this.utils.updatePendingStoreEmployeesInLocalStorage(this.store.id, pending);
    }

}
