import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { AlertController, NavController } from '@ionic/angular';
import { UtilityService } from 'src/services/utility.service';

@Component({
    selector: 'app-my-footer',
    templateUrl: './my-footer.component.html'
})
export class MyFooterComponent {

    @Input()
    showViewCart = false;

    @Output()
    onViewCartClick = new EventEmitter();

    showSelectDelivery = false;

    constructor(
        private utils: UtilityService,
        public router: Router,
        private alertController: AlertController) {
    }

    handleViewCartClick(event) {
        if (!this.utils.cart.deliveryoption) {
            this.showSelectDelivery = true;
            return true;
        }
        this.router.navigate(['/store/' + this.utils.cart.storeid + '/cart']);
    }

    handleDeliveryOptionSelect(event) {
        if (!event) {
            this.showSelectDelivery = false;
            return true;
        }

        if (event && event.type) {
            this.utils.cart.deliveryoption = event.type;
            if (event.type === 'dinein') {
                this.utils.cart.tablenumber = event.tableNumber;
            }
            if (event.type === 'delivery') {
                this.utils.cart.useraddress = event.deliveryAddress;
            }
            localStorage.setItem("cart", JSON.stringify(this.utils.cart));
            this.router.navigate(['/store/' + this.utils.cart.storeid + '/cart']);
        }
    }
}
