import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UtilityService } from "../../../services/utility.service";
import { Store } from "../../../models/store.model";
import { ImageService } from "../../../services/image.service";
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: "app-picture",
  templateUrl: "./picture.component.html"
})

export class PictureComponent implements OnInit {

  @Input()
  name: any;

  @Input()
  id: any;

  @Input()
  isUpload: boolean = false;

  @Input()
  currentPic: any;

  @Input()
  type: string;

  @Input()
  customClass: string;

  @Output()
  uploadImageEvent = new EventEmitter();

  pictureNoPicName: string;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private imageService: ImageService,
    public utils: UtilityService) { }

  ngOnInit() {
    if ((!this.currentPic || this.currentPic === 'undefined' || this.currentPic === 'null') && !this.isUpload) {
      this.setPictureNoPickName();
    }
  }

  setPictureNoPickName() {
    const splitName = this.name.match(/\b(\w)/g);
    this.pictureNoPicName = (splitName[0]).toLowerCase();
  }

  uploadImage(event: any) {
    if (this.isUpload && this.id === undefined) {
      this.id = this.utils.getRandomNumber();
    }
    if (this.id && this.type) {
      const selectedfile = event.target.files
      const file: any = selectedfile.item(0);
      if (file.type.split("/")[0] !== "image") {
        console.log("File type is not supported!");
        return;
      }
      this.imageChangedEvent = event;
      // this.imageService.resizeImage(file, 110).then((resized) => {
      // });
    } else {
      console.error('Image Id or Type Missing.')
    }
  }

  async imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.utils.spinner = true;
    const base64Response = await fetch(`${this.croppedImage}`);
    const blob = await base64Response.blob();
    this.utils.spinner = false;
    this.imageService
      .uploadStoreImageToStorage(blob, this.id, this.type)
      .then(
        (res) => {
          this.utils.spinner = false;
          res.subscribe((uploadedPicture) => {
            const img = {
              uploadedPicture: uploadedPicture,
              id: this.id
            }
            this.uploadImageEvent.emit(img);
          });
        },
        (err) => {
          this.utils.spinner = false;
          console.log(err);
        }
      );
  }

  loadImageFailed() {
  this.utils.showToast('Image failed to upload, try uploading another image')
  }


}
