import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { cashfreeService } from "src/services/cashfree.service";
import { UtilityService } from "../../../services/utility.service";

@Component({
  selector: "app-settlements",
  templateUrl: "./settlements.component.html",
  styleUrls: ["./settlements.component.scss"],
})
export class SettlementsComponent implements OnInit {
  @Input()
  storeid: any;

  settlements: any;

  constructor(private cashfreeService: cashfreeService) {}

  ngOnInit(): void {
    this.getSettlements()
  }

  getSettlements() {
    this.cashfreeService
      .GetSettlements(this.storeid)
      .subscribe((res: any) => {
        this.settlements = res.settlements
        console.log(this.settlements);
      })
  }


}
