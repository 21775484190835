import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { MyAddressPage } from "../../my-address/my-address.page";
import { Store } from "../../../models/store.model";
import { UtilityService } from "../../../services/utility.service";
import { Router } from "@angular/router";
import { Address } from "../../../models/address.model";

@Component({
    selector: "app-select-delivery-option",
    templateUrl: "./select-delivery-option.component.html"
})
export class SelectDeliveryOptionComponent implements OnInit {

    @Output()
    onDeliveryOptionSelect = new EventEmitter();

    marginTop = -250;

    tableNumber: number;
    selectedOption: string;
    deliveryAddress: object;
    isConfirmBtnEnabled = false;
    isCustomerInStore: boolean;
    deliveryDistance: number;
    storeMaxDeliveryDistance: number;

    isCustomer: boolean;

    cartInfo: any;
    isWithInReach: boolean;
    resultPosition: any;

    constructor(
        private utils: UtilityService,
        private alertController: AlertController,
        private modalController: ModalController,
        private router: Router
    ) { }

    ngOnInit() {
        setTimeout(v => {
            this.utils.isAddressChanged.subscribe((res) => {
                if (res) {
                    this.deliveryAddress = this.utils.getSelectedAddress();
                    this.enableDisableConfirmBtn();
                }
            });
            this.isCustomer = this.utils.isUserCustomer(this.utils.cart.storeid);
            this.marginTop = 0;
            this.cartInfo = this.utils.cart;
            this.selectedOption = this.cartInfo.deliveryoption;
            if (!this.selectedOption) {
                this.reset();
                return;
            }
            this.loadSelections();
        }, 0);
    }

    loadSelections() {
        this.selectedOption = this.cartInfo?.deliveryoption;
        if (this.selectedOption === 'dinein') {
            this.tableNumber = this.cartInfo?.tablenumber;
            this.isCustomerInStore = true; // we need to check in validations at the very end
            this.isConfirmBtnEnabled = true;
            return true;
        }
        if (this.selectedOption === 'delivery') {
            this.deliveryAddress = this.utils.getSelectedAddress();
            this.isCustomerInStore = true; // we need to check in validations at the very end
            this.isConfirmBtnEnabled = true;
            return true;
        }

        this.isConfirmBtnEnabled = true;
    }

    reset() {
        this.selectedOption = '';
        this.tableNumber = NaN;
        this.isConfirmBtnEnabled = false;
        this.isCustomerInStore = false;
    }

    dismiss() {
        this.marginTop = -250;
        setTimeout(v => {
            this.onDeliveryOptionSelect.emit(false);
        }, 400);
    }

    enableDisableConfirmBtn() {
        this.isCustomer = this.utils.isUserCustomer(this.utils.cart.storeid);
        let sOptn = this.selectedOption;
        this.isConfirmBtnEnabled = false;
        this.isWithInReach = true;
        if (!this.isCustomer && sOptn !== 'dinein') {
            this.selectedOption = '';
            sOptn = sOptn === 'pickup' ? 'Pick up' : 'Delivery';
            this.utils.showToast(sOptn + ' Option only available for customers.');
            return true;
        }

        if (sOptn === 'pickup' && this.cartInfo.store.ispickup) {
            this.isConfirmBtnEnabled = true;
            return true;
        }

        if (sOptn === 'delivery' && this.cartInfo.store.isdelivery) {
            this.deliveryAddress = this.utils.getSelectedAddress();
            if (this.deliveryAddress && this.isDeliveryAddressWithinStoreReach()) {
                this.isConfirmBtnEnabled = true;
                return true;
            }
        }

        if (sOptn === 'dinein' && this.isCustomerInStore && this.tableNumber && this.cartInfo.store.isdinein) {
            this.isConfirmBtnEnabled = true;
            return true;
        }
    }

    async selectDeliveryOption(selection) {
        this.utils.spinner = true;
        this.selectedOption = selection;
        if (this.selectedOption === 'dinein') {
            // we will actually check this at the review order; since we have to check again there
            const inStore = await this.isUserInsideStore(this.utils.cart.store);
            this.isCustomerInStore = !inStore ? true : false; // need to remove !(not)  - just for testing!
        }
        this.enableDisableConfirmBtn();
        this.utils.spinner = false;
    }

    async isUserInsideStore(storeInfo) {
        const instance = this;
        let success = await function (pos) {
          if (pos?.coords?.latitude) {
            instance.resultPosition = pos;
          }
        };
        this.utils.getCurrentPosition(success);
        if (this.resultPosition) {
            const currentPosition = {
                latitude: this.resultPosition.coords.latitude,
                longitude: this.resultPosition.coords.longitude
            }
        
            const distance = this.utils.getDistanceBetweenPoints(
                currentPosition,
                storeInfo.position.geopoint, 'km').toFixed(2);
    
            const storeradius = 0.10;
            if (parseInt(distance) < storeradius) {
                return true
            } else {
                return false
            }
        }
    }


    proceedToCart() {
        if (this.selectedOption) {
            let event: any = { type: this.selectedOption };
            if (this.selectedOption === 'dinein' && this.tableNumber) {
                event.tableNumber = this.tableNumber;
            } else if (this.selectedOption === 'delivery') {
                event.deliveryAddress = this.deliveryAddress;
            }
            this.onDeliveryOptionSelect.emit(event);
        }
    }

    async openAddAddress() {
        this.router.navigate(['./my-address']);
    }

    // Checking if the delivery address selected is within the store max deivery distance
    // for this we need to reverse geocode the address of the delivery address entered.
    isDeliveryAddressWithinStoreReach() {
        const selectedAddress: any = this.deliveryAddress;
        const distance = this.utils.getDistanceBetweenPoints(
            selectedAddress.latlong,
            this.cartInfo.store.position.geopoint, 'km').toFixed(2);
        this.cartInfo.deliverydistance = distance;
        this.utils.cart = this.cartInfo;
        if (parseInt(distance) < this.cartInfo.store.maxdeliverydistance) {
            this.isWithInReach = true;
            this.cartInfo = this.utils.getCalculatedOrder(this.cartInfo);
            return true;
        }
        this.isWithInReach = false;
        this.utils.showToast('No Service available for the below entered address. Please change and try again');
        return false;
    }

}
