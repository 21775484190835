import { Pipe, PipeTransform } from '@angular/core';
import { formatISO, format, parseISO } from 'date-fns';

@Pipe({
    name: 'timemilli'
})

export class TimeMilli implements PipeTransform {

    public transform(value: any): any {
        if (value) {
            const inMins = value/(1000*60);
            if (inMins < 60) {
                return inMins + ' min';
            }
            if (inMins > 60 && inMins < 60 *24) {
                return Math.ceil(inMins/60) + ' hr';
            }
            if (inMins > 60*24) {
                return Math.ceil((inMins/(60*24))) + ' d';
            }
        }
        return value;
    }

    //code
}  