export class Address {
    id: any;
    contactname: '';
    contactphone: number;
    addresstype: '';
    housenumber: '';
    streetaddress: '';
    landmark: '';
    latlong: { latitude: any; longitude: any};
    default: boolean = false;
    address: any;
    selected: boolean;

    constructor(address?) {
        this.contactname = (address && address.contactname) || "";
        this.contactphone = (address && address.contactphone) || 0;
        this.addresstype = (address && address.addresstype) || "";
        this.housenumber = (address && address.housenumber) || "";
        this.streetaddress = (address && address.streetaddress) || "";
        this.landmark = (address && address.landmark) || "";
        this.latlong = (address && address.latlong) || "";
        this.default = (address && address.default) || false;
        this.selected = (address && address.selected) || false;
    }
}