export class Item {
  id: any;
  type: any; // 'lessprice', 'couponcash', 'cashback', 'freegift', 'poff', 'bogo', 'bogopoff'
  category: any;
  deal: any; // 200, 50, BOGO, bogo50, Free Gift
  was: any; // previous price,
  on: any; //
  weight: any; // 1,
  units: any; // { name: 'Gram', value: 'kg' }
  available: any;
  special: boolean;
  pic: any;
  zone: any;
  isveg: boolean;

  constructor(item?) {
    this.type = (item && item.type) || "";
    this.category = (item && item.category) || "";
    this.deal = (item && item.deal) || "";
    this.was = (item && item.was) || "";
    this.on = (item && item.on) || "";
    this.weight = (item && item.weight) || "";
    this.units = (item && item.units) || "";
    this.available = (item && item.available) || "";
    this.special = (item && item.special) || "";
    this.pic = (item && item.pic) || "";
    this.zone = (item && item.zone) || "";
    this.isveg = (item && item.isveg) || false;
  }
}
