import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { StoreService } from "../../services/store.service";
import { UtilityService } from "../../services/utility.service";
import { Store } from "src/models/store.model";

@Component({
    selector: "app-store",
    templateUrl: "./store.page.html",
    styleUrls: ["./store.page.scss"],
})
export class StorePage {
    promotions = [];
    storeItems: any = [];
    storeId: string;
    categoryItems: Array<any> = [];
    // specialItems: any = [];

    isEdit = false;
    storeNoPicName: string;
    showEmployeeAcceptBtn: boolean;
    isEmployee: any;

    storeInfo: Store;
    isStoreServicable: boolean;
    showSelectDelivery = false;

    distance: string;

    constructor(
        private router: Router,
        public utils: UtilityService,
        private storeService: StoreService
    ) { }


    ngOnInit() {
        this.storeItems = [];
        this.storeInfo = null;
        const path = this.router.url;
        this.storeId = path.split("/store/")[1];
        if (this.utils.uid === this.storeId) {
            this.isEdit = true;
        } else {
            this.isEdit = false;
        }
        this.getStoreDetails(this.storeId);
    }

    ionViewWillEnter() {
        let cartUpdatedItems = [];
        if (this.storeItems && this.storeItems.length > 0) {
            if (this.utils.cart?.items) {
                cartUpdatedItems = this.updateItemsWithCartItems(this.storeItems);
                this.separateStoreItems(cartUpdatedItems);
            } else {
                this.storeItems.forEach(item => item.quantity = 0);
                this.separateStoreItems(this.storeItems);
            }
        }
    }

    getStoreDetails(id) {
        const storeInfoSubscription = this.storeService.getStoreInfo(id).subscribe((result) => {
            if (result !== undefined) {
                this.storeInfo = result;
                this.checkStoreServicableFlag();
                this.utils.updateRecentVisits(result);
                this.getStoreItems(id);
                this.calculateDistance(result);
                storeInfoSubscription.unsubscribe();
            }
        });
    }

    async calculateDistance(result) {
        const instance = this;
        let success = function (pos) {
            let userLatLong: any = instance.utils.location?.latlong || null;
            if (pos?.coords?.latitude) {
                if (pos.coords && instance.utils.location.latlong) {
                    const distance = instance.utils.getDistanceBetweenPoints(pos.coords, instance.utils.location.latlong, 'km').toFixed(2);
                    if (parseInt(distance) < 10) {
                        userLatLong = pos.coords;
                    }
                }
            }
            instance.distance = instance.utils.getDistanceBetweenPoints(userLatLong, result.position.geopoint, 'km').toFixed(2);
        };
        let error = function (err) {
            instance.distance = instance.utils.getDistanceBetweenPoints(instance.utils.location?.latlong, result.position.geopoint, 'km').toFixed(2);
        }
        await this.utils.getCurrentPosition(success, error)
    }

    checkStoreServicableFlag() {
        const si = this.storeInfo;
        if (
            (si.status !== 'transferred') ||
            (!si.ispickup && !si.isdelivery && !si.isdinein) ||
            (si.open === 'mclose')) {
            this.isStoreServicable = false;
        } else {
            this.isStoreServicable = true;
        }
    }

    getStoreItems(storeId) {
        this.storeItems = [];
        const items = this.storeService
            .getStoreItems(storeId)
            .subscribe((res) => {
                if (res && res.length > 0) {
                    this.storeItems = res;
                    const cartUpdatedItems = this.updateItemsWithCartItems(res);
                    this.separateStoreItems(cartUpdatedItems);
                }
                items.unsubscribe();
            });
    }

    // This method is called when the user leaves the store page and comes back
    // and cart items need to be added in store;
    updateItemsWithCartItems(items) {
        if (
            this.utils?.cart &&
            items.length > 0 &&
            this.storeInfo?.id === this.utils?.cart.storeid &&
            Object.keys(this.utils?.cart.items).length > 0) {
            items.forEach((item: any) => {
                const cartItem = this.utils?.cart?.items[item.id];
                if (cartItem) {
                    item.quantity = cartItem.quantity;
                } else {
                    item.quantity = 0;
                }
            });
        }

        return items;
    }

    separateStoreItems(items) {
        // this.specialItems = [];
        this.categoryItems = [];
        this.createStoreCategories();
        items.forEach((i) => {
            if (i.available === '1') {
                if (i.special === "1") {
                    this.categoryItems[0].items.push(i);
                } 
                // else {
                //     const categoryIndex = this.categoryItems.map(object => object.name).indexOf(i.category);
                //     this.categoryItems[categoryIndex].items.push(i);
                // }
                    const categoryIndex = this.categoryItems.map(object => object.name).indexOf(i.category);
                    this.categoryItems[categoryIndex].items.push(i);
            }
        });
        this.categoryItems = this.categoryItems.filter((group: any) => group.items.length > 0);
    }

    // creating array for each category in store to separate items
    createStoreCategories() {
        const storeCategories = this.storeInfo.categories;
        if (storeCategories && storeCategories.length > 0) {
            this.categoryItems = [];
            this.storeInfo.categories.forEach((v) => {
                const newcategory = { name: v, items: [] };
                this.categoryItems.push(newcategory);
            });
             // makeing sure its in first place
            this.categoryItems.unshift({ name: 'Store Specials', items: [] });

             // making sure Other Category is in last position (This may not be needed once we implement move categories)
            const indexOfOtherCategory = this.categoryItems.map(object => object.name).indexOf('Other');
            if (indexOfOtherCategory > -1) {
                this.categoryItems.push(this.categoryItems.splice(indexOfOtherCategory, 1)[0]);
            }
        }
    }

    goToOrderDetails() {
        this.utils.lastPage = "store/" + this.storeId;
        this.router.navigate(["cart/new"]);
    }

    handleFooterLinksEvent(event) {
        if (event === "reload") {
            this.utils.reloadPage();
        }
        this.router.navigate([event + '/' + this.storeId]);
    }

    handleViewCartClick(event) {
        if (!this.utils.cart.deliveryoption) {
            this.showSelectDelivery = true;
            return true;
        }
        this.router.navigate(['/store/' + this.utils.cart.storeid + '/cart']);
    }

    async handleDeliveryOptionSelect(event) {
        this.showSelectDelivery = false;
        if (!event) {
            return true;
        }

        if (event && event.type) {
            this.utils.cart.deliveryoption = event.type;
            if (event.type === 'dinein') {
                this.utils.cart.tablenumber = event.tableNumber;
            }
            if (event.type === 'delivery') {
                this.utils.cart.useraddress = event.deliveryAddress;
            }
            this.utils.getCalculatedOrder(this.utils.cart);
            this.router.navigate(['/store/' + this.utils.cart.storeid + '/cart']);
        }
    }

    scrollToCategory(category) {
        document.getElementById(category).scrollIntoView({ behavior: "smooth", block: "center" });
    }
}
