import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UtilityService } from './utility.service';
@Injectable({
  providedIn: 'root'
})
export class cashfreeService {
  vendorDetails: {};
  Int : Number;
  constructor(private http: HttpClient, private utils: UtilityService) { }



  initiateTransaction(orderid, amount) {
    let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/token/token?' + '&orderId=' + orderid + '&orderAmount=' + amount;
    return this.http.get(url)
  }

  GetBalance(vendorId) {
    let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/addVendor/getbalance?' + '&vendorId=' + vendorId;
    return this.http.get(url)
  }

  GetSettlements(vendorId) {
    let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/addVendor/settlements?' + '&vendorId=' + vendorId;
    return this.http.get(url)
  }

  verifyOrder(orderId) {
    let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/addVendor/verifyorder?' + '&orderId=' + orderId;
    return this.http.get(url)
  }

  addVendor(upi, accountHolder, upistatus, email) {
   
    this.vendorDetails = {
      email: email,
      status: 'ACTIVE',
      upi : {
        "vpa": upi,
        "accountHolder": accountHolder
      },
      phone: this.utils.phoneNumber,
      name: this.utils.fullName,
      id: this.utils.uid,
      settlementCycleId: 2
    };

    if (upistatus === 'active') {
      let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/addVendor/update';
     return this.http.put(url,this.vendorDetails);
    } else {
     let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/addVendor/add';
     return this.http.post(url,this.vendorDetails);
    }    
  }

  intializeRefund(order) {

    const refundParams = {
      order_id: order.id,
      refund_amount: 10.00, //order.grandtotal,
      refund_id: 'refund' + order.id,
      refund_splits: [ 
        { 
          "vendor_id": order.storeid,
          "amount": 10.00 //order.grandtotal,
        }
      ] 
    };

    let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/addVendor/refund';
    return this.http.post(url, refundParams);
  }

  GetRefundStatus(orderId, refundId) {
    let url = 'https://us-central1-my-town-market-new.cloudfunctions.net/addVendor/refundstatus?' + '&orderId=' + orderId+ '&refundId=' + refundId;
    return this.http.get(url)
  }

}

