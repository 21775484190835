import { Injectable } from "@angular/core";
import { Order } from "../models/order.model";

@Injectable({
    providedIn: "root",
})

export class OrderValidate {

    constructor() { }

    public isValid(order: Order) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (order) {
                    resolve({ text: 'success' });
                } else {
                    resolve({ text: 'error', errors: [{ text: 'Example error here; what ever' }]})
                }
            }, 3000);
        });
    }

    isDineInValidated() {

    }

    isDeliveryValidated() {

    }

    isPickupValidated() {

    }

    isDistanceTooFar() {

    }

    isCustomerInStore() {

    }
}
