import { Component, Input, OnInit } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { UtilityService } from "../../../services/utility.service";
import { AlertController, ModalController } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: "app-promotion-item",
  templateUrl: "./promotion-item.component.html"
})

export class PromotionItemComponent implements OnInit {

  @Input()
  store: any;

  // storeId: string;
  // storeName: string;
  // storeLocation: string;
  // storeCity: string;
  // storeZip: string;

  constructor(
    public utils: UtilityService,
    private orderService: OrderService,
    private modalController: ModalController,
    private router: Router,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    // if (this.item && this.item.storeinfo) {
    //   const storeInfo = this.item.storeinfo.split('-');
    //   this.storeId = storeInfo[0];
    //   this.storeName = storeInfo[1].replace(/_/g, ' ');
    //   this.storeLocation = storeInfo[2].replace(/_/g, ' ');
    //   this.storeCity = storeInfo[3].replace(/_/g, ' ');
    //   this.storeZip = storeInfo[4].replace(/_/g, ' ');
    // }
  }

  goToStorePage() {
    this.router.navigate(["./store/" + this.store.id]);
  }

}
