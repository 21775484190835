import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UtilityService } from "src/services/utility.service";

@Component({
  selector: "app-payment-options",
  templateUrl: "./payment-options.component.html"
})
export class PaymentOptionsComponent implements OnInit {

  @Input()
  storeInfo: any;

  @Input()
  isCustomer: boolean

  @Input()
  isGuestOrder: boolean

  @Output()
  paymentSelected = new EventEmitter<any>();
  upiDisabled: boolean = false;

  constructor(
    private utils: UtilityService
  ) {}

  ngOnInit() {
    console.log(this.utils.platform);
    if (this.storeInfo.upistatus !== 'active' && !this.storeInfo?.paymentdetails?.upiaddress && this.utils.platform !== 'android' || this.utils.platform === null ) {
      this.upiDisabled = true ;
      console.log(this.storeInfo);
      
    }
    console.log(this.upiDisabled);

  }

  selectPaymentType(event) {
    console.log(this.isGuestOrder);
    
    this.paymentSelected.emit(event.detail.value);
  }

}
