import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController, NavController } from "@ionic/angular";
import { UtilityService } from "../../../services/utility.service";

@Component({
    selector: "app-cart-items",
    templateUrl: "./cart-items.component.html"
})
export class CartItemsComponent implements OnInit {

    @Input()
    items: any;

    itemsLength: number;

    @Input()
    showButtons = false;

    @Output()
    cartItemsEvent = new EventEmitter<any>();

    constructor(
        private utils: UtilityService,
        private alertController: AlertController,
        private navCtrl: NavController) { }

    ngOnInit(): void {
        if (this.utils.cart) {
            this.items = this.utils.cart?.items;
        }
        this.itemsLength = this.utils.getOrderItemsLength(this.items);
    }

    decreaseItem(key) {
        if (this.itemsLength > 1) {
            if (this.items[key].quantity === 1) {
                delete this.items[key];
            } else {
                this.items[key].quantity = this.items[key].quantity - 1;
            }
            this.emitEvent('decrease');
        } else {
            this.deleteCancelOrder();
        }
    }

    increaseItem(key) {
        this.items[key].quantity = this.items[key].quantity + 1;
        this.emitEvent('decrease');
    }

    emitEvent(type) {
        this.utils.cart.items = this.items;
        this.utils.cart.itemscount = this.utils.getOrderItemsLength(this.items);
        this.itemsLength = this.utils.cart.itemscount;
        localStorage.setItem("cart", JSON.stringify(this.utils.cart));
        
        this.cartItemsEvent.emit({ type, cart: this.utils.cart });
    }

    async deleteCancelOrder() {
        const word = "delete";
        const alert = await this.alertController.create({
            header: "Confirm!",
            message: "Do you want to " + word + " the entire order?",
            buttons: [
                { 
                    text: "No",
                    // role: "cancel" 
                },
                { 
                    text: "Yes", 
                    handler: () => { this.confirmDelete(word); } 
                },
            ],
        });
        await alert.present();
    }

    confirmDelete(word) {
        this.utils.resetCart();
        this.navCtrl.back();
    }

}
