import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';
import { PromotionItemComponent } from './promotion-item/promotion-item.component';
import { ItemComponent } from './item/item.component';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { AddItemPage } from './add-item/add-item.page';
import { AddLocalityPage } from './add-locality/add-locality.page';
import { TabsComponent } from './tabs/tabs.component';
import { MyHeaderComponent } from './my-header/my-header.component';
import { StorePromotionComponent } from './store-promotion/store-promotion.component';
import { EmployeeCartComponent } from './employee-cart/employee-cart.component';
import { BillingComponent } from './billing/billing.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { BalanceComponent } from './balance/balance.component';
import { EmployeesComponent } from './employees/employees.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { SettlementsComponent } from './settlements/settlements.component';
import { MapComponent } from './map/map.component';
import { OrderTimelineComponent } from './order-timeline/order-timeline.component';
import { TimeAgo } from '../../pipes/time-ago.pipe';
import { LabelPipe } from '../../pipes/label.pipe';
import { TimeMilli } from 'src/pipes/time-milli.pipe';
import { Ms } from '../../pipes/time-ago.pipe';
import { ObjectLengthPipe } from 'src/pipes/object-length.pipe';
import { StoreFooterComponent } from './store-footer/store-footer.component';
import { FiltersComponent } from './filters/filters.component';
import { PopOverComponent } from './popover/popover';
import { PictureComponent } from './picture/picture.component';
import { OrderItemComponent } from './order-item/order-item.component';
import { SelectDeliveryOptionComponent } from './select-delivery-option/select-delivery-option.component';
import { CompleteOrderComponent } from './complete-order/complete-order.component';
import { MyFooterComponent } from './my-footer/my-footer.component';
import { AddMapAddressComponent } from './add-map-address/add-map-address.component';

import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        ImageCropperModule
    ],
    declarations: [
        AddItemPage,
        AddLocalityPage,
        PromotionItemComponent,
        ItemComponent,
        CartItemsComponent,
        TabsComponent,
        MyHeaderComponent,
        MyFooterComponent,
        StorePromotionComponent,
        EmployeeCartComponent,
        BillingComponent,
        PaymentOptionsComponent,
        SettlementsComponent,
        TransactionsComponent,
        EmployeesComponent,
        BalanceComponent,
        MapComponent,
        OrderTimelineComponent,
        PopOverComponent,
        StoreFooterComponent,
        FiltersComponent,
        PictureComponent,
        OrderItemComponent,
        SelectDeliveryOptionComponent,
        AddMapAddressComponent,
        CompleteOrderComponent,
        TimeAgo,
        ObjectLengthPipe,
        LabelPipe,
        TimeMilli,
        Ms
    ],
    exports: [
        PromotionItemComponent,
        TabsComponent,
        ItemComponent,
        CartItemsComponent,
        MyHeaderComponent,
        MyFooterComponent, // check if we need the components to be specified in both exports and declarations
        StorePromotionComponent,
        EmployeeCartComponent,
        BillingComponent,
        PaymentOptionsComponent,
        SettlementsComponent,
        TransactionsComponent,
        EmployeesComponent,
        BalanceComponent,
        OrderTimelineComponent,
        MapComponent,
        PopOverComponent,
        StoreFooterComponent,
        FiltersComponent,
        PictureComponent,
        OrderItemComponent,
        SelectDeliveryOptionComponent,
        AddMapAddressComponent,
        CompleteOrderComponent,
        TimeAgo,
        ObjectLengthPipe,
        LabelPipe,
        TimeMilli,
        Ms
    ]
})

export class ComponentsModule { }
