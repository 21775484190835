import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { UtilityService } from "../../../services/utility.service";
import { ValidationService } from "../../../services/validation.service";
import { AddPromotionConfig } from "../../../config/add-promotion.config.";
import { CategoryService } from "../../../services/category.service";
import { Item } from "../../../models/item.model";
import { StoreService } from "../../../services/store.service";
import { ImageService } from "../../../services/image.service";
import { Store } from "../../../models/store.model";

@Component({
    selector: "app-add-item",
    templateUrl: "./add-item.page.html",
    styleUrls: ["./add-item.page.scss"],
})
export class AddItemPage implements OnInit {

    @Input()
    item: Item;

    @Input()
    store: Store

    itemModel = new Item();
    pageTitle = "Add Item";
    availableCategories: Array<object>;
    availableWeightUnits: Array<object>;
    selectedPromotionConfig: string;
    validations: any;
    items: Array<any>;

    constructor(
        private addPromotionConfig: AddPromotionConfig,
        public utils: UtilityService,
        private router: Router,
        private modalController: ModalController,
        private categoryService: CategoryService,
        private storeService: StoreService,
        private validationService: ValidationService,
        private alertController: AlertController,
        private imageService: ImageService
    ) { }

    ngOnInit() {
        if (this.item && this.item["id"]) {
            this.pageTitle = "Edit Item";
            this.itemModel = {
                id: this.item.id,
                type: this.item.type,
                category: this.item.category,
                deal: this.item.deal,
                was: this.item.was,
                on: this.item.on,
                weight: this.item.weight,
                units: this.item.units,
                available: this.item.available,
                special: this.item.special,
                pic: this.item.pic,
                zone: this.item.zone,
                isveg: this.item.isveg
            };
        }
        this.initialize();
    }

    initialize() {
        this.validations = this.validationService.promotionValidations();
        this.availableCategories = this.getCategoriesBusinessTypeList();
        this.availableWeightUnits = this.addPromotionConfig.getWeightUnits();
    }

    getCategoriesBusinessTypeList() {
        let list: Array<any> = this.store.categories;
        return list;
    }

    validate(event, field) {
        const validateField = this.validations["lessprice"][field];
        return validateField(event);
    }

    isContinueBtnEnable() {
        const im = this.itemModel;
        if (
            !im ||
            !im.category ||
            !im.deal ||
            !im.on ||
            !im.special ||
            !im.available
        ) {
            return false;
        }

        return true;
    }

    continue() {
        const itemParams = this.getUpdateItemParams();
        this.storeService.createUpdateItems(
            this.store.id, itemParams
        )
            .then((res) => {
                if (this.item && this.item["id"]) {
                    this.utils.showToast(this.utils.messages?.itemUpdated);
                } else {
                    this.utils.showToast(this.utils.messages?.itemCreated);
                    this.itemModel = new Item();
                }

                this.modalController.dismiss(itemParams);
            })
            .catch((err) => this.utils.handleServiceErrors(err));
    }

    getUpdateItemParams() {
        const zone = this.itemModel.zone || this.store.address.locality
        return {
            id: this.itemModel.id,
            type: this.itemModel.type,
            category: this.itemModel.category,
            deal: this.itemModel.deal,
            was: this.itemModel.was,
            on: this.itemModel.on,
            weight: this.itemModel.weight,
            units: this.itemModel.units,
            available: this.itemModel.available,
            special: this.itemModel.special,
            pic: this.itemModel.pic,
            isveg: this.itemModel.isveg || false,
            zone
        };
    }

    dismiss() {
        this.modalController.dismiss();
    }

    handlePictureAfterUpload(event) {
        if (event.id) {
            this.itemModel.id = event.id;
        }
        this.itemModel.pic = event.uploadedPicture;
    }

}
