import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'label'
})
export class LabelPipe implements PipeTransform {

  transform(option: string, args?: any): string {
    if (option) {
      return this.getLabelForKey(option, args);
    }
    return '';
  }

  getLabelForKey(key, args?) {
    const labels = {
        'dinein': 'Dine in',
        'pickup': 'Pick up',
        'delivery': 'Delivery',
        'new': 'Order Placed',
        'received': 'Started Preparation',
        'ready': 'Order Ready',
        'pickedup': 'Order Picked up',
        'completed': 'Order Completed',
        'filters': {
            'new': 'New Orders',
            'received': 'Started',
            'ready': 'Completed',
            'delivered': 'Delivered',
            'cash': 'Cash Orders',
            'online': 'Online Orders'
        },
        'paymenttype': {
            'cash': 'Cash',
            'upi': 'Online UPI',
            'pending': 'Cash/Online UPI'
        }
    }
    return args ? labels[args][key] : labels[key] || '';
  }

}