import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UtilityService } from 'src/services/utility.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit  {
  selectTab: any;

  constructor(
    public utils: UtilityService, private navCtrl: NavController, private router: Router) {}

    ngOnInit(): void {
     this.setCurrentTab();
    }

    setCurrentTab() {
      const url = this.router.url;
      const path = url.split('/')[1];
      this.selectTab = path;      
    }

  public gotoPage(page: string): void {
    if (page === 'orders') {
      this.utils.setUserOrdersInStorage();
    }
    this.navCtrl.navigateRoot([page]);
  }

}

/*
guest 
new - Order placed - Confirm
received - Started Preparation - ready
ready - Ready to Pick up - Picked up (Picked up by customer)
complete

guest 
new - Order placed - Confirm
received - Started Preparation - Call Delivery - query if employee availablefordelivery flag
ready - Ready to Pick up - Picked up (only assigned employee/delivery partner)
pickedup - Picked up by Delivery - complete (only assigned employee/delivery partner)
complete

Assign Delivery

*/
