import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class AddPromotionConfig {

    // public getRequiredFieldsForType(type) {
    //     const promotionsConfig = this.getConfig(),
    //     result = promotionsConfig.filter(v => v.type === type);
    //     return result.length > 0 ? result[0].requiredFields : [];
    // }

    // public getAllPromotionTypes() {
    //     const promotionsConfig = this.getConfig(),
    //     allPromoTypes = [];
    //     promotionsConfig.forEach(item => {
    //         allPromoTypes.push(item.type);
    //     });
    //     return allPromoTypes;
    // }

    public getWeightUnits() {
        return [
            { name: 'Kg', value: 'kg' },
            { name: 'Grams', value: 'g' },
            { name: 'Unit', value: 'unit' },
            { name: 'Lb', value: 'lb' }
        ];
    }

    public getConfig() {
        return {
            'amountoff': {
                labels: {
                    deallabel: 'Amount Off',
                    // waslabel: 'Previous Price',
                    onlabel: 'On', // Bill Amount, Categories, Items
                    uptolabel: '',
                    minlabel: 'Minimum Bill (optional)',
                    codelabel: '6 Digit Code (optional)'
                },

                requiredFields: ['deal', 'on']
            },
            'poff': {
                labels: {
                    deallabel: 'Percentage off',
                    // waslabel: 'Previous Price',
                    onlabel: 'On', // Bill Amount, Categories, Items
                    uptolabel: 'Max Allowed Amount (optional)',
                    minlabel: 'Minimum Bill (optional)',
                    codelabel: '6 Digit Code (optional)'
                },

                requiredFields: ['deal', 'on']
            }
        }
    }

    public getConfigOld() {
        return [
            {
                type: 'amountoff',

                sample: {
                    category: 'tea',
                    deal: '170',
                    on: 'Red Label Tea',
                    was: '300',
                    weight: '1 Kg',
                    units: { name: 'Kg', value: 'kg' }
                },

                labels: {
                    typelabel: 'Flat amount off',
                    typedesc: 'Usually used on Single Items when the price is less than previous price.',
                    deallabel: 'Sale Price',
                    waslabel: 'Previous Price',
                    onlabel: 'Product Name',
                    weightlabel: 'Weight (Optional)'
                },

                requiredFields: ['deal', 'on', 'was']
            },
            {
                type: 'poff',

                sample: {
                    category: 'clothes',
                    deal: '50',
                    on: 'Cosmetics & Womens Clothing'
                },

                labels: {
                    typelabel: 'Percentage off',
                    typedesc: '(examples - 20% Off on Mens Jeans, 30% off on Womens Tops & Dresses)',
                    deallabel: 'Percentage Off (in numbers)',
                    waslabel: '',
                    onlabel: 'Product Name',
                    weightlabel: 'Weight (Optional)'
                },

                requiredFields: ['deal', 'on']
            },
            {
                type: 'cashback',

                sample: {
                    category: 'grocery',
                    deal: '200',
                    on: '1500'
                },

                labels: {
                    typelabel: 'Cashback on Min Bill Amount',
                    typedesc: 'Cashback Amount / Discount right away when customer spends above Minimum Amount',
                    deallabel: 'Cashback Amount',
                    waslabel: '',
                    onlabel: 'Min Bill Amount',
                    weightlabel: ''
                },

                requiredFields: ['deal', 'on']
            },
            {
                type: 'couponcash',

                sample: {
                    category: 'grocery',
                    deal: '500',
                    on: '3000'
                },

                labels: {
                    typelabel: 'Coupons on Min Bill Amount',
                    typedesc: 'Coupons Amount when customer spends above Minimum Amount',
                    deallabel: 'Coupon Amount',
                    waslabel: '',
                    onlabel: 'Min Bill Amount',
                    weightlabel: ''
                },

                requiredFields: ['deal', 'on']

            },
            {
                type: 'bogo',

                sample: {
                    category: 'biryani',
                    on: 'Chicken Boneless Biryani',
                    deal: '500',
                },

                labels: {
                    typelabel: 'Buy 1 Get 1 Free',
                    typedesc: 'Two for the price of one',
                    deallabel: 'Price',
                    waslabel: '',
                    onlabel: 'Product Name',
                    weightlabel: ''
                },

                requiredFields: ['on', 'deal']
            },
            {
                type: 'bogopoff',

                sample: {
                    category: 'shoes',
                    deal: '20',
                    on: 'Rebook Sports Shoes',
                },

                labels: {
                    typelabel: 'Buy 1 get 1 xx % Off',
                    typedesc: '(examples - Buy 1 get 1 50% Off, Buy 1 get 1 20% Off)',
                    deallabel: 'Percentage Off',
                    waslabel: '',
                    onlabel: 'Product Name',
                    weightlabel: '',
                }, 

                requiredFields: ['deal', 'on']
            },
            {
                type: 'freegift',

                sample: {
                    category: 'cosmetics',
                    on: '2500',
                    was: 'Travel Bag'
                },

                labels: {
                    typelabel: 'Free Gift on Min Bill Amount',
                    typedesc: 'Any gift above min purchase order',
                    deallabel: '',
                    waslabel: 'Name of the Free Gift',
                    onlabel: 'Min Bill Amount',
                    weightlabel: '',
                },

                requiredFields: ['was', 'on']
            }
        ];
    }
}
