import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../services/auth.guard";
import { UserGuardService } from "../services/user.guard";
import { LocationGuardService } from "../services/location.guard";

const routes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("./home/home.module").then((m) => m.HomePageModule)
    },
    {
        path: "food",
        loadChildren: () =>
            import("./stores/stores.module").then((m) => m.StoresPageModule),
    },
    {
        path: "meat",
        loadChildren: () =>
            import("./stores/stores.module").then((m) => m.StoresPageModule),
    },
    {
        path: "grocery",
        loadChildren: () =>
            import("./stores/stores.module").then((m) => m.StoresPageModule),
    },
    {
        path: "offers/stores",
        loadChildren: () =>
            import("./offers/offers.module").then((m) => m.OffersPageModule)
    },
    {
        path: "offers/items",
        loadChildren: () =>
            import("./offers/offers.module").then((m) => m.OffersPageModule)
    },
    {
        path: "account",
        loadChildren: () =>
            import("./account/account.module").then(
                (m) => m.AccountPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "orders",
        loadChildren: () =>
            import("./orders/orders.module").then((m) => m.OrdersPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: "store-orders",
        loadChildren: () =>
            import("./store-orders/store-orders.module").then((m) => m.StoreOrdersPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: "orders/:id",
        loadChildren: () =>
            import("./order-details/order-details.module").then(
                (m) => m.OrderDetailsPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "store-orders/:id",
        loadChildren: () =>
            import("./order-details/order-details.module").then(
                (m) => m.OrderDetailsPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "add-promotion/:id",
        loadChildren: () =>
            import("./add-promotion/add-promotion.module").then(
                (m) => m.AddPromotionPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "contact-us",
        loadChildren: () =>
            import("./contact-us/contact-us.module").then(
                (m) => m.ContactUsPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "faq",
        loadChildren: () => import("./faq/faq.module").then((m) => m.FaqPageModule),
    },
    {
        path: "location",
        loadChildren: () =>
            import("./location/location.module").then((m) => m.LocationPageModule),
    },
    {
        path: "notifications",
        loadChildren: () =>
            import("./notifications/notifications.module").then(
                (m) => m.NotificationsPageModule
            ),
    },
    {
        path: "partner",
        loadChildren: () =>
            import("./partner/partner.module").then((m) => m.PartnerPageModule),
    },
    // {
    //     path: "sign-up",
    //     loadChildren: () =>
    //         import("./sign-up/sign-up.module").then((m) => m.SignUpPageModule),
    //     canActivate: [AuthGuardService],
    // },
    {
        path: "store/:id",
        loadChildren: () =>
            import("./store/store.module").then((m) => m.StorePageModule)
    },
    {
        path: "terms",
        loadChildren: () =>
            import("./terms/terms.module").then((m) => m.TermsPageModule),
    },
    // {
    //     path: "select-promotion-type",
    //     loadChildren: () =>
    //         import("./select-promotion-type/select-promotion-type.module").then(
    //             (m) => m.SelectPromotionTypePageModule
    //         ),
    // },
    {
        path: "add-address",
        loadChildren: () =>
            import("./add-address/add-address.module").then((m) => m.AddAddressPageModule)
    },
    {
        path: 'about-us',
        loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsPageModule)
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
    },
    {
        path: 'refund-policy',
        loadChildren: () => import('./refund-policy/refund-policy.module').then(m => m.RefundPolicyPageModule)
    },
    {
        path: 'my-profile',
        loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule)
    },
    {
        path: 'my-address',
        loadChildren: () => import('./my-address/my-address.module').then(m => m.MyAddressPageModule)
    },
    {
        path: 'landing',
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule)
    },
    {
        path: "sign-in",
        loadChildren: () =>
            import("./sign-in/sign-in.module").then((m) => m.SignInPageModule),
    },
    {
        path: "sign-in/:id",
        loadChildren: () =>
            import("./sign-in/sign-in.module").then((m) => m.SignInPageModule)
    },
    {
        path: 'store/:id/cart',
        loadChildren: () => import('./cart/cart.module').then(m => m.CartPageModule)
    },
    {
        path: 'payment-details',
        loadChildren: () => import('./payment-details/payment-details.module').then(m => m.PaymentDetailsPageModule),
        canActivate: [UserGuardService],
    },
    {
        path: 'search-user',
        loadChildren: () => import('./search-user/search-user.module').then(m => m.SearchUserPageModule),
        canActivate: [UserGuardService],
    },
    {
        path: 'employees',
        loadChildren: () => import('./list/list.module').then(m => m.ListPageModule),
        canActivate: [UserGuardService],
    },
    {
        path: 'transactions',
        loadChildren: () => import('./list/list.module').then(m => m.ListPageModule),
        canActivate: [UserGuardService],
    },
    {
        path: 'settlements',
        loadChildren: () => import('./list/list.module').then(m => m.ListPageModule),
        canActivate: [UserGuardService],
    },
    {
        path: 'store-dashboard/:id',
        loadChildren: () => import('./store-dashboard/store-dashboard.module').then(m => m.StoreDashboardPageModule),
        canActivate: [UserGuardService],
    },
    {
        path: 'store-dashboard/:id/store-items',
        loadChildren: () => import('./store-items/store-items.module').then(m => m.StoreItemsPageModule),
        canActivate: [UserGuardService],
    },
    {
        path: 'review-order',
        loadChildren: () => import('./review-order/review-order.module').then(m => m.ReviewOrderPageModule),
        canActivate: [UserGuardService],
    },
  {
    path: 'add-store',
    loadChildren: () => import('./add-store/add-store.module').then( m => m.AddStorePageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then( m => m.OffersPageModule)
  }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
