import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, OnInit, SimpleChanges } from "@angular/core";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { UtilityService } from "src/services/utility.service";

declare var google;

@Component({
    selector: "app-map",
    templateUrl: "./map.component.html"
})
export class MapComponent implements OnInit {

    @Output()
    onMapPointSelected = new EventEmitter<any>();

    @Input()
    searchLatLong;

    @ViewChild('map', { static: false }) mapElement: ElementRef;
    map: any;
    address: string;

    latitude: number;
    longitude: number;

    constructor(
        private geolocation: Geolocation,
        private nativeGeocoder: NativeGeocoder,
        private utils: UtilityService
    ) { }

    ngOnInit() {
        // if (!this.searchLatLong) {
        //     this.getCurrentPosition();
        // }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.searchLatLong) {
            this.loadMap(this.searchLatLong);
        }
    }

    // getCurrentPosition() {
    //     this.geolocation.getCurrentPosition().then((resp) => {

    //         const respLatLong = {
    //             latitude: resp.coords.latitude,
    //             longitude: resp.coords.longitude
    //         }

    //         const queryLatLong = resp.coords ? respLatLong : this.utils.location.position.geopoint;

    //         this.getaddressFromGeo(queryLatLong);
    //         this.loadMap(queryLatLong);

    //     }).catch((error) => {
    //         // console.log('Error getting location', error);
    //     });
    // }

    async loadMap(queryLatLong) {
        let latLng = await new google.maps.LatLng(queryLatLong.latitude, queryLatLong.longitude);
        let mapOptions = {
            center: latLng,
            zoom: 18,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
        }

        this.map = await new google.maps.Map(this.mapElement.nativeElement, mapOptions);

        this.map.addListener('dragend', () => {

            this.latitude = this.map.center.lat();
            this.longitude = this.map.center.lng();

            const addressLatLong = {
                latitude: this.map.center.lat(),
                longitude: this.map.center.lng()
            }
            this.onMapPointSelected.emit({ addressLatLong });

            // this.getaddressFromGeo(addressLatLong);
            // this.getAddressFromCoords(this.map.center.lat(), this.map.center.lng())
        });
    }


    // async getaddressFromGeo(userEnteredAddress) {
    //     let geocoder = await new google.maps.Geocoder();
    //     let latlng = await new google.maps.LatLng(userEnteredAddress.latitude, userEnteredAddress.longitude);
    //     let request = { latLng: latlng };

    //     await geocoder.geocode(request, (results, status) => {
    //         if (status == google.maps.GeocoderStatus.OK) {
    //             let result = results[0];
    //             console.log('result', result);

    //             const streetAddress = result.formatted_address;
    //             const addressLatLong = {
    //                 latitude: result.geometry.location.lat(),
    //                 longitude: result.geometry.location.lng()
    //             }

    //             this.onMapPointSelected.emit({ streetAddress, addressLatLong });
    //         }
    //     });
    // }


}
