import { Injectable } from "@angular/core";
import {
    AngularFirestoreCollection,
    AngularFirestore,
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { UtilityService } from "./utility.service";
import { Order } from '../models/order.model';
import { Store } from "../models/store.model";
import { Observable } from "rxjs";
import * as firebase from "firebase";
import { OrderValidate } from "../validations/order.validate";

@Injectable({
    providedIn: "root",
})
export class OrderService {
    orderRef: AngularFirestoreCollection;
    formRef: AngularFirestoreCollection;

    lastVisibleUserOrder: any;
    loadingUserOrdersCompleted = false;

    constructor(
        public Afs: AngularFirestore,
        private utils: UtilityService,
        private orderValidate: OrderValidate) { }

    public async createStoreOrder(result, orderId) {
        result.billing = JSON.parse(JSON.stringify(result.billing));
        result.useraddress = JSON.parse(JSON.stringify(result.useraddress));
        delete result.store;
        let isValidated: any = await this.orderValidate.isValid(result);
        if (isValidated?.text === 'success') {
            this.orderRef = this.Afs.collection<Order>('orders');
            return await this.orderRef.doc(orderId).set({ ...result }, { merge: true });
        }
        return isValidated;
    }

    public async updateOrderStatus(orderId, result) {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection<Order>('orders');
        await this.orderRef.doc(orderId).update(result);
        this.utils.spinner = false;
    }

    public getOrderById(id) {
        return this.Afs.collection("orders").doc(id).ref.get();
    }

    public async deleteOrderRec(id, result) {
        this.orderRef = this.Afs.collection<Order>('orders');
        await this.orderRef.doc(id).set({ ...result });
        // await this.Afs.collection("orders").doc(id).delete();
    }

    public getOrderDetails(orderId) {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection('orders');
        return this.orderRef.doc(orderId).snapshotChanges().pipe(
            map((res: any) => {
                this.utils.spinner = false;
                const result = res.payload.data();
                if (result) {
                    result.id = res.payload.id;
                }
                return result;
            })
        );
    }

    public getAllStoreOrders(storeId) {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection('orders', (ref) =>
            ref.where('storeid', '==', storeId).orderBy('ordereddate', 'desc')
        );
        return this.orderRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (!res || res.length === 0) {
                    return [];
                }
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            })
        );
    }

    public getStoreOrders(storeId, lastCheckedDate) {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection('orders', (ref) =>
            ref.where('storeid', '==', storeId).orderBy('updatedon').startAfter(lastCheckedDate)
        );
        return this.orderRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (!res || res.length === 0) {
                    return [];
                }
                const lastVisible = res[res.length - 1].payload.doc;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            })
        );
    }

    public getStoreEmployeeOrders(storeId, employeeId, lastCheckedDate) {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection('orders', (ref) =>
            ref.where('storeid', '==', storeId).where('assigneeid', '==', employeeId).orderBy('updatedon').startAfter(lastCheckedDate)
        );
        return this.orderRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (!res || res.length === 0) {
                    return [];
                }
                const lastVisible = res[res.length - 1].payload.doc;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            })
        );
    }


    // public getEmployeeOrder(uid) {
    //   const lastCheckedDate = this.utils.getStoreOrdersLastCheckedDate();
    //   const search = ( key, value ) => {
    //     this.orderRef = this.Afs.collection('orders', (ref) =>
    //     ref.where(key, '==', value).orderBy('updatedon').startAfter(lastCheckedDate)
    //   );
    //   } 
    //   Promise.all( [ search( 'createdby', uid), search( 'deliverybyid', uid ) ] )
    //   .then(([one,two]:any) =>  one.concat(two) )
    //   .then( allResults => {
    //   return this.orderRef.snapshotChanges().pipe(
    //       map((res) => {
    //         this.utils.spinner = false;
    //         if (!res || res.length === 0) {
    //           return [];
    //         }
    //         return res.map((dataItems) => {
    //           const data = dataItems.payload.doc.data(),
    //             id = dataItems.payload.doc.id;
    //           return { id, ...data };
    //         })
    //       })
    //     );
    //   })
    // }

    // public getEmployeeStoreOrders(storeId, userId) {
    //   this.utils.spinner = true;
    //   const lastCheckedDate = this.utils.getStoreOrdersLastCheckedDate();
    //   this.orderRef = this.Afs.collection('orders', (ref) =>
    //     ref.where('storeid', '==', storeId).where('deliverybyid', '==', userId).orderBy('updatedon').startAfter(lastCheckedDate)
    //   );
    //   return this.orderRef.snapshotChanges().pipe(
    //     map((res) => {
    //       this.utils.spinner = false;
    //       if (!res || res.length === 0) {
    //         return [];
    //       }
    //       return res.map((dataItems) => {
    //         const data = dataItems.payload.doc.data(),
    //           id = dataItems.payload.doc.id;
    //         return { id, ...data };
    //       })
    //     })
    //   );
    // }

    public getUserOrders(uid, lastCheckedDate): Observable<Array<any>> {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection('orders', (ref) =>
            ref.where('userid', '==', uid).orderBy('updatedon').startAfter(lastCheckedDate)
        );
        return this.orderRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (!res || res.length === 0) {
                    return [];
                }
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            })
        );
    }

    public getUserOrdersNewUser(phonenumber, lastCheckedDate): Observable<Array<any>> {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection('orders', (ref) =>
            ref.where('userphonenumber', '==', phonenumber).orderBy('updatedon').startAfter(lastCheckedDate)
        );
        return this.orderRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (!res || res.length === 0) {
                    return [];
                }
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            })
        );
    }



    public getPaginatedUserOrders(uid, limit): Observable<Array<any>> {
        this.utils.spinner = true;
        this.orderRef = this.Afs.collection('orders', (ref) =>
            ref.where('userid', '==', uid).orderBy('updatedon', 'desc').limit(limit).startAfter(this.lastVisibleUserOrder)
        );
        return this.orderRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (!res || res.length === 0) {
                    return [];
                }
                if (res.length < limit) {
                    this.loadingUserOrdersCompleted = true;
                }
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            })
        );
    }

    getPreparedOrder(store) {
        // const timestamp = firebase.firestore.FieldValue.serverTimestamp;
        const order: any = {};
        order.orderid = this.utils.getRandomOrderNumber();
        order.storeid = store.id;
        order.storename = store.name;
        order.storelocationname = store.address.sublocality;
        order.storeaddress = store.address;
        order.storecity = store.address.locality;
        order.storepic = store.pic;
        order.storezipcode = store.address.zipcode;
        order.storeupistatus = store?.upistatus || '';
        order.storeupiaddress = store?.paymentdetails?.upiaddress || '';
        order.userid = this.utils.uid;
        order.username = this.utils.fullName;
        order.userphonenumber = this.utils.phoneNumber;
        order.userlocationname = this.utils.location.locality;
        order.userpic = this.utils?.profilePic || '';
        order.usercity = this.utils.location.sublocality || this.utils.location.locality;
        order.ordereddate = this.utils.getNewDate();
        order.status = null;// ordered:  any; pending:  any; completed:  any; cancelled , reordered: any
        order.updatedon = this.utils.getNewDate(); // this needs to change to actual date
        order.items = {};
        order.note = '';
        order.paymentstatus = store.paymentstatus || '';
        order.useraddress = '';
        order.createdbyid = this.utils.uid;
        order.createdbyname = this.utils.fullName;
        order.assigneeid = store.id;
        order.assigneename = store.name;
        order.assigneephonenumber = store.storephone;
        order.deliveryoption = ''; // By Default
        order.currentpaymentstatus = 'pending';
        return order;
    }

    // public getPromotionsOrders(id, date) {
    //   this.utils.spinner = true;
    //   this.orderRef = this.Afs.collection('orders', (ref) =>
    //     ref.where('storeid', '==', id).where('status', '==', 'new').where('orderdate', '>', date)
    //   );
    //   return this.orderRef.snapshotChanges().pipe(
    //     map((res) => {
    //       this.utils.spinner = false;
    //       if (!res || res.length === 0) {
    //         return [];
    //       }
    //       return res.map((dataItems) => {
    //         const data = dataItems.payload.doc.data(),
    //           id = dataItems.payload.doc.id;
    //         return { id, ...data };
    //       })
    //     })
    //   );
    // }

    // public saveOrderToStorage(userId, record) {
    //   let allOrders = this.getSavedOrders(userId) || [];
    //   allOrders.unshift(record);
    //   localStorage.setItem(userId + "orders", JSON.stringify(allOrders));
    // }

    // public getSavedOrders(userId) {
    //   return JSON.parse(localStorage.getItem(userId + "orders"));
    // }
}
