import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectLength'
})
export class ObjectLengthPipe implements PipeTransform {

  transform(obj: unknown, ...args: unknown[]): unknown {
    if (obj) {
      return Object.keys(obj).length;
    }
    return 0;
  }

}
