export class Order {
  id: any;
  orderid: string;
  store: any; // this is not mandatory and will be removed before sending to order
  storeid: string;
  storename: string;
  storelocationname: string;
  storecity: string;
  storepic: string; // remove this
  storeaddress: string;
  storezipcode: string;
  userid: string;
  username: string;
  useraddress: any;
  userphonenumber: any;
  userpic: string;
  userlocationname: any;
  usercity: any;
  ordereddate: any;
  items: any;
  itemscount: number;
  billing: Billing;
  status: any; // ordered:  any; pending:  any; completed:  any; cancelled , reordered: any
  updatedon: any; // this needs to change to actual date
  updatedbyid: any;
  paymenttype: string;
  note: string;
  paymentstatus: any;
  createdbyid: any;
  createdbyname: any;
  deliveryoption: any;
  assigneename: any;
  assigneeid: any;
  assigneephonenumber: any;
  currentstatus: string;
  estimatedpreparationtime: any;
  estimateddeliverytime: any;
  tablenumber: number;
  currentpaymentstatus: string;
  errors: any;
  deliverydistance: number;

  constructor(order?) {
    this.orderid = (order && order.orderid) || "";
    this.storeid = (order && order.storeid) || "";
    this.storename = (order && order.storename) || "";
    this.storelocationname = (order && order.storelocationname) || "";
    this.storecity = (order && order.storecity) || "";
    this.storepic = (order && order.storepic) || "";
    this.storeaddress = (order && order.storeaddress) || "";
    this.storezipcode = (order && order.storezipcode) || "";
    this.userid = (order && order.userid) || "";
    this.username = (order && order.username) || "";
    this.userphonenumber = (order && order.userphonenumber) || "";
    this.userpic = (order && order.userpic) || "";
    this.ordereddate = (order && order.ordereddate) || "";
    this.items = (order && order.items) || [];
    this.itemscount = (order && order.itemscount) || 0;
    this.status = (order && order.status) || null; // ordered:  any; pending:  any; completed:  any; cancelled , reordered: any
    this.updatedon = (order && order.updatedon) || ""; // this needs to change to actual date
    this.updatedbyid = (order && order.updatebyid) || "";
    this.billing = new Billing();
    this.paymenttype = order && order.paymenttype || '';
    this.note = (order && order.note) || null;
    this.paymentstatus = (order && order.paymentstatus) || null;
    this.useraddress = (order && order.useraddress) || "";
    this.userlocationname = (order && order.userlocationname) || "";
    this.usercity = (order && order.usercity) || "";
    this.createdbyid = (order && order.createdbyid) || "";
    this.createdbyname = (order && order.createdbyname) || "";
    this.deliveryoption = (order && order.deliveryoption) || "";
    this.assigneename = (order && order.assigneename) || "";
    this.assigneeid = (order && order.assigneeid) || "";
    this.assigneephonenumber = (order && order.assigneephonenumber) || "";
    this.currentstatus = (order && order.currentstatus) || "";
    this.estimatedpreparationtime = (order && order.estimatedpreparationtime) || "";
    this.estimatedpreparationtime = (order && order.estimateddeliverytime) || "";
    this.tablenumber = (order && order.tablenumber) || NaN;
    this.currentpaymentstatus = (order && order.currentpaymentstatus) || "";
    this.errors = (order && order.errors) || null;
    this.deliverydistance = (order && order.deliverydistance) || 0;
  }
}

export class Billing {
    subtotal: number;
    storepromotiondiscount: number;
    itemdiscount: number;
    service: number;
    gst: number;
    packaging: number;
    deliverycharge: number;
    additionalcharge: number;
    tip: number;
    grandtotal: number;

    constructor(billing?) {
        this.subtotal = billing && billing.subtotal || 0;
        this.storepromotiondiscount = billing && billing.storepromotiondiscount || 0;
        this.itemdiscount = billing && billing.itemdiscount || 0;
        this.service = billing && billing.service || 0;
        this.gst = billing && billing.gst || 0;
        this.packaging = billing && billing.packaging || 0;
        this.deliverycharge = billing && billing.deliverycharge || 0;
        this.additionalcharge = billing && billing.additionalcharge || 0;
        this.tip = billing && billing.tip || 0;
        this.grandtotal = billing && billing.grandtotal || 0;
    }
}

export class OrderItems {
  type: string;
  category: string;
  promoid: string;
  deal: string;
  was: string;
  on: string;
  weight: string;
  units: string;
}

/*

getStoreOrders() {} by storeid:  any; status != 'completed':  any; updateddate > lastpulleddate (or may be order by updated date)

getUserOrders() {} by userid:  any; updateddate > lastpulleddate

*/
