import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UtilityService } from "../../../services/utility.service";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"],
})
export class TransactionsComponent implements OnInit {
  @Input()
  items: any;

  settlements: any;

  constructor(private utils: UtilityService) {}

  ngOnInit(): void {
  }

}
