import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { UtilityService } from "../../../services/utility.service";
import { AlertController, ModalController } from "@ionic/angular";

@Component({
    selector: "app-item",
    templateUrl: "./item.component.html",
    styleUrls: ["./item.component.scss"],
})
export class ItemComponent {

    @Input()
    store: any;

    @Input()
    item: any;

    @Input()
    isEdit: boolean;

    @Input()
    btnLabel: string = "Add";

    @Input()
    canAdd: boolean = false;

    @Output()
    itemEditEvent = new EventEmitter();

    buttonSpinner = false;
    pendingAddItem: any = null;
    showSelectDelivery = false;

    constructor(
        public utils: UtilityService,
        private orderService: OrderService,
        private modalController: ModalController,
        private alertController: AlertController
    ) { }

    addItemToCart(event, item, type) {
        console.log(item);
        
        event && event.preventDefault();
        if (!this.buttonSpinner) { // making sure double clicks does not happen
            if (this.utils.cart && this.utils.cart.storeid !== this.store.id) {
                this.showCartItemsLeaveAlert(item);
                return true;
            }

            // This just never happen but
            // By any chance if the cart is negative
            if (this.utils.cart && this.utils.cart.itemscount < 0) {
                this.utils.resetCart();
                this.utils.reloadPage();
            }

            if (!this.utils.cart) {
                this.utils.cart = this.orderService.getPreparedOrder(this.store);
                this.utils.cart.store = this.store;
            }

            this.proceedAddToCart(item, type);
        }
    }

    proceedAddToCart(item, type) {
        this.buttonSpinner = true;
        this.addToCart(item, type);
        setTimeout((v) => {
            this.buttonSpinner = false;
        }, 1000);
    }

    async showCartItemsLeaveAlert(item) {
        const alert = await this.alertController.create({
            header: "Replace Items",
            cssClass: "store-page-alert",
            message:
                "<br>Your cart contains dishes from " + this.utils.cart.storename + "Do you want to discard the selection and add dishes from" + this.store.name + "?",
            buttons: [
                {
                    text: "YES",
                    handler: () => {
                        this.utils.resetCart();
                        this.addItemToCart(null, item, 'plus');
                    },
                },
                {
                    text: "NO"
                },
            ],
        });

        await alert.present();
    }

    addToCart(item, type) {
        const orderItems = this.utils.cart.items;
        if (orderItems[item.id]) {
            if (type === 'plus') {
                item.quantity = item.quantity + 1;
                orderItems[item.id] = item;
            } else if (type === 'minus') {
                item.quantity = item.quantity - 1;
                orderItems[item.id] = item;
                if (item.quantity === 0) {
                    delete orderItems[item.id];
                    // return;
                    if (Object.keys(orderItems).length === 0) {
                        this.utils.resetCart();
                        return;
                    }
                }
            }
        } else {
            item.quantity = 1;
            orderItems[item.id] = item;
        }
        this.utils.cart = this.utils.getCalculatedOrder(this.utils.cart);
    }

    editItem(item) {
        this.itemEditEvent.emit(item);
    }
}
