import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController, ModalController, PopoverController } from "@ionic/angular";
import { Order } from "../../../models/order.model";
import { StoreService } from "../../../services/store.service";
import { UtilityService } from "../../../services/utility.service";
import { Router } from "@angular/router";
import { cashfreeService } from "../../../services/cashfree.service";

declare let PgCordovaWrapper: any;

@Component({
    selector: "app-complete-order",
    templateUrl: "./complete-order.component.html"
})
export class CompleteOrderComponent implements OnInit {

    @Input()
    order: Order;

    @Input()
    orderUpdate: string;

    @Output()
    onOrderComplete = new EventEmitter<any>();

    cftoken: any;
    buttonSpinner = false;

    constructor(
        private popover: PopoverController,
        private utils: UtilityService,
        private storeService: StoreService,
        private modalController: ModalController,
        private cashfreeService: cashfreeService,
        private alertController: AlertController,
        private router: Router
    ) { }

    ngOnInit() {
        // this.order.currentstatus === 'new' ? this.Btn = "PayOnline" : this.Btn = "Place Order";
    }

    placeOrder(event) {
        event && event.preventDefault();
        if (!this.buttonSpinner) {
            this.buttonSpinner = true;
            const storeInfoSubscription =
                this.storeService.getStoreInfo(this.order.storeid).subscribe((result) => {
                    storeInfoSubscription.unsubscribe();
                    if (!result || (result && result.open === 'mclose')) {
                        this.processStoreClosedOrders();
                        this.buttonSpinner = false;
                        return;
                    }
                        
                    this.handleSelectPayTypeResponse();
                });
        }
    }

    handleSelectPayTypeResponse() {
        if (this.order.paymenttype === "upi") {
            this.initiateTransaction();
        } else {
            this.onOrderComplete.emit({ type: 'pending', proceed: true });
            this.buttonSpinner = false;
        }
    }

    initiateTransaction() {
        const cashfreeInitialize = this.cashfreeService
            .initiateTransaction(this.order.orderid, 10)
            .subscribe((res: any) => {
                if (res && res.cftoken) {
                    this.cftoken = res.cftoken;
                    this.openUpi(this.cftoken);
                } else {
                    this.order.orderid = this.utils.getRandomOrderNumber();
                    this.buttonSpinner = false;
                    // show alert saying that there is problem with transaction
                    // do you want to proceed with cash only order
                    // always check if the store accepts cash only
                }
                cashfreeInitialize.unsubscribe();
            });
    }

    openUpi(cftoken) {
        const appId = "181050271a2604af266b005d66050181";
        let prepareSplit = [
            {
                "vendorId": this.order.storeid,
                "percentage": 100
            }
        ];
        let objJsonStr = btoa(JSON.stringify(prepareSplit));
        let params = this.getCashFreeParams(appId, cftoken, objJsonStr);
        let that = this;

        PgCordovaWrapper.startPaymentUPI(
            params,
            function (response) {
                let res = JSON.parse(response);
                if (res.txStatus === 'SUCCESS') {
                    that.handleTransactionSuccessResponse(res);
                } else {
                    console.log(res.txStatus);
                    
                    that.utils.showToast(res.txStatus);
                }
            },
            function (error) {
                console.log(error.txStatus);

                that.utils.showToast("PAYMENT" + error.txStatus);
                that.order.orderid = this.utils.getRandomOrderNumber();

                that.buttonSpinner = false;
                that.utils.showToast("Payment Failed!" + error);
            }
        );
    }

    getCashFreeParams(appId, cftoken, objJsonStr) {
        return {
            appId: appId, //app-id here
            orderId: this.order.orderid,
            orderAmount: this.order.billing.grandtotal,
            orderCurrency: "INR",
            orderNote: "Cashfree",
            customerName: this.utils.fullName,
            customerPhone: this.utils.phoneNumber,
            customerEmail: "rishisiliveri70@gmail.com",
            stage: "PROD",
            tokenData: cftoken, //cftoken here,
            paymentSplits: objJsonStr
        };
    }

    handleTransactionSuccessResponse(res) {
        let paymentresult: any;
        this.cashfreeService.verifyOrder(this.order.orderid).subscribe(async (result) => {
            paymentresult = result;
            if (paymentresult.status === 'OK' && paymentresult.orderStatus === 'PAID') {
                this.order.paymentstatus = {
                    'txStatus': paymentresult.txStatus,
                    'userupi': paymentresult.paymentDetails.vpa,
                    'referenceId': res.referenceId
                };
                this.utils.showToast("Payment Successful!");
                this.onOrderComplete.emit({ type: 'upipaid', paymentInfo: this.order.paymentstatus, proceed: true });
                this.buttonSpinner = false;
            } else {
                this.utils.showToast("PAYMENT" + paymentresult.txStatus);
                this.order.orderid = this.utils.getRandomOrderNumber();
                this.buttonSpinner = false;
                // case where the payment is not success
                // may be give the user a chance to submit a cash order ---
                // but before check if the store is accepting a cash order ---
                // something like please use a different payment provider or 
                // place order at different time
            }
        });
    }

    async proceedCaution() {
        const message = "The store is now closed. Do you still want to proceed with the order?";
        const alert = await this.alertController.create({
            header: "Are you sure ?",
            message,
            buttons: [
                { text: "No" },
                { text: "Yes", handler: () => { this.handleSelectPayTypeResponse(); } },
            ],
        });
        await alert.present();
    }

    // When store is closed --------------------------------
    // the order will proceed only if someone has already ordered and paying the bill or
    // if the user already is in store at some time in the night when store closed (at this
    // point only employee can proceed with caution)
    //------------------------------------------------------
    processStoreClosedOrders() {
        if (!this.utils.isUserCustomer(this.order.storeid)) {
            this.proceedCaution();
        } else {
            this.utils.showToast("Sorry the Store is now closed!");
            this.utils.resetCart();
            this.router.navigate(["./"]);
        }
    }


}
