import { Injectable } from '@angular/core';
import {
    AngularFirestoreCollection,
    AngularFirestore,
} from '@angular/fire/firestore';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root',
})

export class CategoryService {
    categoryRef: AngularFirestoreCollection;

    constructor(public Afs: AngularFirestore, private utils: UtilityService) { }

    public moreBusinessTypes() {
        return [
            { name: 'Buying / Rental Service', value: '' },
            { name: 'Uber like from your location to some other location', value: '' },
            { name: 'Other Services - Electricians, plumbers, AC Repat Services', value: '' }
        ];
    }

    public getCategoriesForBusinessType(type) {
        const allcategories = this.getBusinessTypesAndCategories(),
        filteredList = allcategories.filter(v => v.type === type);

        return filteredList && filteredList[0] ? filteredList[0].categories : [];
    }

    public getBusinessTypes() {
        const list = this.getBusinessTypesAndCategories(),
        types = [];
        list.forEach(item => {
            types.push({ name: item.label, value: item.type });
        });
        return types;
    }

    public getBusinessTypesAndCategories() {
        return [
            {   
                type: 'grocery',
                label: 'Super Market / Grocery Store / Vegetables / Fruits',
                categories: ['Stationary', 'Fruits', 'Vegetables', 'Meat', 'Eggs', 'Milk', 'Clothes', 'Shoes', 'Accessories', 
                    'Electronics', 'Oils', 'Cosmetics', 'Baby Care', 'Personal Care', 'Teas & Coffee', 'Bakery',
                    'Biscuits', 'Chocolates', 'Rice & Grains', 'Atta & Flours', 'Spices', 'Organic', 'Pulses', 'Other'
                ]
            },
            {
                type: 'food',
                label: 'Food',
                categories: ['Biryani', 'Vegetarian', 'Non Vegetarian', 'Tiffins', 'Ice Cream',
                    'Shawarma', 'Burger', 'Pizza', 'Cakes', 'Pastries', 'Biscuits', 'Coffee', 'Tea',
                    'Fast Food', 'Chocolates', 'Candy', 'Sandwich', 'Desert', 'Snack', 'Shakes', 'Other'
                ]
            },
            {
                type: 'meat',
                label: 'Meat Shop',
                categories: ['Chicken', 'Mutton', 'Fish', 'Seafood', 'Lamb',
                    'Goat', 'Beef', 'Pork', 'Other'
                ]
            },
            // {
            //     type: 'hotel',
            //     label: 'Hotel / Lodge / Inn / Motel',
            //     categories: ['AC Rooms', 'Non AC Rooms', 'Luxury Rooms', 'Marriage Hall',
            //         'Convention Center', 'Function Hall', 'Party Hall'
            //     ]
            // },
            // {
            //     type: 'apparels',
            //     label: 'Clothes Showroom / Fashion Outlets',
            //     categories: ['Mens', 'Momens', 'Sunglasses', 'Kids', 'Dresses', 'Suits',
            //         'Tops', 'Bottoms', 'Innerware', 'Underwear', 'Lingerie'
            //     ]
            // }
        ]
    }

    public createBusinessTypesAndCategoriesBulkUpdate() {
        const list = this.getBusinessTypesAndCategories(),
        types = [];
        list.forEach(item => {
            this.createCategory(item);
        });
    }

    public async createCategory(result) {
        this.utils.spinner = true;
        this.categoryRef = this.Afs.collection<BTypeCategory>("btypecategories");
        await this.categoryRef.add({ ...result });
        this.utils.spinner = false;
    }

}

export class BTypeCategory {
    type: string;
    label: string;
    categories: Array<string>;

    constructor(item?) {
        this.type = item && item.type || '';
        this.label = item && item.label || '';
        this.categories = item.categories || [];
    }
}

