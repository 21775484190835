import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { SearchUserPage } from "src/app/search-user/search-user.page";
import { StoreService } from "src/services/store.service";
import { UtilityService } from "../../../services/utility.service";

@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html"
})
export class EmployeesComponent implements OnInit {

  employeesList: any;
  pendingEmployeesList: Array<any>;
  store: any;
  employeeTypeToAdd: string;

  constructor(
    private utils: UtilityService,
    private storeService: StoreService,
    private modalController: ModalController,
    private alertController: AlertController,
    private navCtrl: NavController
  ) { }

  ngOnInit(): void {
    this.store = this.utils.userOwnStore;
    this.pendingEmployeesList = this.utils.getPendingStoreEmployeesInLocalStorage(this.store.id);
    this.getEmployees(); // we can get this too from localstorage if we store it
  }

  getEmployees() {
    const storeEmployee = this.storeService
      .getStoreEmployees(this.store.id)
      .subscribe((res) => {
        this.employeesList = res;
        storeEmployee.unsubscribe();
      });
  }

  removeEmployee(item) {
    if (item.status === "pending") {
      this.removeFromPendingEmployee(item);
    } else if (item.status === "active") {
      this.removeFromApprovedEmployee(item);
    }
    this.storeService.deleteEmployee(this.store.id, item.id).then((res) => {
      this.utils.showToast(item.fullname + 'Removed Successfully');
      this.getEmployees();
      this.getStoreDetails(this.store.id);
    });
  }

  removeFromPendingEmployee(item) {
    let pending = this.store.pendingemployees || [];
    pending = pending.filter(item => item !== item.uid);
    this.storeService.updateStore(this.store.id, { pendingemployees: pending });
  }

  removeFromApprovedEmployee(item) {
    let approved = this.store.approvedemployees || [];
    approved = approved.filter(item => item !== item.uid);
    this.storeService.updateStore(this.store.id, { approvedemployees: approved });
  }

  async ConfirmRemoveEmployee(item) {
    const alert = await this.alertController.create({
      header: "Confirm Delete Employee",
      buttons: [
        { text: "Cancel" },
        {
          text: "Ok",
          handler: (data: any) => this.removeEmployee(item),
        },
      ],
    });
    await alert.present();
  }


  async openSearchUserPage(type) {
    this.employeeTypeToAdd = type;
    const modal = await this.modalController.create({
      component: SearchUserPage
    });
    modal.onDidDismiss().then((res) => {
      if (res && res.data) {
        this.addEmployee(res.data);
      }
    });
    return await modal.present();
  }

  addEmployee(res) {
    //to check employee is already added
    const si = this.utils.userOwnStore,
      pendingEmployees = si.pendingemployees || [],
      approvedEmployees = si.approvedemployees || [];

    if (pendingEmployees.includes(res.id)) {
      this.utils.showToast('Invitation Already Sent!');
      return;
    }
    if (approvedEmployees.includes(res.id)) {
      this.utils.showToast('Already An Active Employee.');
      return;
    }
    pendingEmployees.push(res.id);
    const params = {
      pendingemployees: pendingEmployees
    };

    this.storeService.updateStore(this.utils.userOwnStore?.id, params)
      .then((result) => {
        this.pendingEmployeesList.push(res);
        this.utils.updatePendingStoreEmployeesInLocalStorage(this.store.id, res);
        this.utils.showToast('Employee Added Successfully');
        this.getEmployees();
        this.getStoreDetails(this.store.id);
      });
  }

  // addEmployeetoSubCollection(status, res) {
  //   //from storeowner - res
  //   const employeeDetails: any = {
  //     name: res.fullname,
  //     uid: res.id,
  //     phonenumber: res.phonenumber,
  //     status: status,
  //     type: this.employeeTypeToAdd
  //   };

  //   this.storeService.addEmployeetoSubCollection(this.utils.userOwnStore?.id, res.id, employeeDetails)
  //     .then((res) => {
  //       if (status === 'Active') {
  //         this.utils.showToast('Joined as Employee Successfully');
  //       }
  //     }
  //     )
  // }

  getStoreDetails(id) {
    const storeInfoSubscription = this.storeService.getStoreInfo(id).subscribe((result) => {
      if (result !== undefined) {
        this.store = result;
        this.utils.updateUserStoreInfoInLocalStorage(this.utils.uid, this.store);
        storeInfoSubscription.unsubscribe();
      } else {
        this.navCtrl.navigateRoot(['./home']);
      }
    });
  }

}
