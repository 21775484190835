import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { cashfreeService } from "src/services/cashfree.service";
import { UtilityService } from "../../../services/utility.service";

@Component({
    selector: "app-balance",
    templateUrl: "./balance.component.html",
    styleUrls: ["./balance.component.scss"],
})
export class BalanceComponent implements OnInit {
    @Input()
    storeid: any;
    balance: any;
    amount: any;
    date: any;
    settlementamount: any;

    constructor(
        private cashfreeService: cashfreeService,
        private utils: UtilityService
    ) { }

    ngOnInit(): void {
        if (this.utils.userOwnStore?.upistatus && this.utils.userOwnStore.upistatus === 'active') {
            this.getBalance()
        }
    }

    getBalance() {
        this.utils.spinner = true;
        this.cashfreeService
            .GetBalance(this.storeid)
            .subscribe((res) => {
                this.utils.spinner = false;
                this.balance = res
                this.amount = this.balance.vendorDetails.balance;
                this.date = this.balance.vendorDetails.nextSettlementDetails.date;
                this.settlementamount = this.balance.vendorDetails.nextSettlementDetails.settlementAmount
            })
    }


}
