import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.html"
})

export class PopOverComponent {


  @Input()
  cartInfo: any;

  @Input()
  showDelivery : boolean = false;

  constructor(private popover: PopoverController) {
   }

  ClosePopover() {
    this.popover.dismiss();
  }

}
